import { useState } from "react";
import { Editor as TinyMCEEditor } from "@tinymce/tinymce-react";

import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";

import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/anchor";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/paste";
import "tinymce/plugins/help";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/wordcount";

import { generateEditorId } from "../../hooks/helpers";
import React from "react";

const Editor = React.forwardRef<HTMLDivElement, any>(
  ({ value, onChange, disabled, onKeyUp }, ref) => {
    const [id] = useState(generateEditorId());

    return (
      <div ref={ref}>
        <TinyMCEEditor
          apiKey="oa2ty8hncticqyt8pvkf7dlwxnmnq5n9bof4ehim0vgkfxin"
          disabled={disabled}
          id={`editor-${id}`}
          value={value}
          onEditorChange={onChange}
          onKeyUp={onKeyUp}
          init={{
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help imagetools wordcount"
            ],
            toolbar:
              "fontselect | fontsizeselect | " +
              "bold italic underline forecolor removeformat | alignment | " +
              `numlist bullist | link image code | backcolor | unlink | outdent indent |`,
            fontsize_formats:
              "10px 12px 13px 14px 16px 18px 24px 36px 48px 72px 78px",
            font_formats:
              "Andale Mono=andale mono,times; " +
              "Arial=arial,helvetica,sans-serif; " +
              "Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; " +
              "Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Calibri=calibri; Georgia=georgia,palatino; " +
              "Helvetica=helvetica; Impact=impact,chicago; Sans Serif=sans-serif;  Symbol=symbol; " +
              "Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; " +
              "Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            toolbar_groups: {
              alignmentgroup: {
                icon: "alignment-icon",
                tooltip: "Alignment",
                items: "alignleft aligncenter  alignright | alignjustify"
              }
            },
            skin_url: "/assets/sh"
          }}
        />
      </div>
    );
  }
);
Editor.displayName = "Editor";
export default Editor;
