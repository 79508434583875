// Components
import TableAction from "./table-action";
import TableFooter from "./table-footer";
import TableHeader from "./table-header";
import NoData from "../no-data/no-data";
import moment from "moment";
import { IoIosRefresh } from "react-icons/io";
import { useEffect, useLayoutEffect, useState } from "react";
import TableSkeletonLoading from "./table-skeleton-loading";
import TableStatus from "./table-status";
import Popover from "../popover/popover";
import Input from "../input/input";

type FieldProps = {
  key: string;
  label: string;
  type: string;
};

type MetaProps = {
  page: number;
  pageSize: number;
  totalCount: number;
};

const Table = ({
  fields,
  dataList,
  meta,
  onPageChange,
  onEdit,
  onView,
  onDelete,
  deleteUrl,
  onAdd,
  title,
  isLoading,
  searchValue,
  onSearch,
  refreshData,
  activateStatusUrl,
  deactivateStatusUrl,
  permission,
  showRefreshBtn = false,
  onRefresh
}: {
  fields: FieldProps[];
  dataList: any;
  meta: MetaProps | null;
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onSearch?: (e: any) => void;
  searchValue?: string;
  onEdit?: (id: number, label: string) => void;
  onView?: (id: number, label: string) => void;
  onRefresh?: () => void;
  onDelete?: () => void;
  onUploadBtnClick?: () => void;
  deleteUrl?: string;
  onAdd?: () => void;
  title: string;
  isLoading: boolean;
  refreshData?: () => void;
  activateStatusUrl?: string;
  deactivateStatusUrl?: string;
  permission?: any;
  showRefreshBtn?: boolean;
  showImportBtn?: boolean;
}) => {
  const people = [
    {
      name: "Lindsay Walton",
      title: "Front-end Developer",
      email: "lindsay.walton@example.com",
      role: "Member"
    }
    // More people...
  ];

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }
  // const checkbox: any = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState<any[]>([]);

  useLayoutEffect(() => {
    const isIndeterminate: any =
      selectedPeople.length > 0 && selectedPeople.length < people.length;
    setChecked(selectedPeople.length === people.length);
    setIndeterminate(isIndeterminate);
    // checkbox.current.indeterminate = isIndeterminate;
  }, [selectedPeople]);

  const toggleAll = () => {
    setSelectedPeople(checked || indeterminate ? [] : people);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  const [isVisible, setIsVisible] = useState(false);

  const formateDate = (date: string) => {
    return moment(date).format("DD/MM/YYYY");
  };

  const displayTableValue = (
    type: string,
    value: string,
    id: number,
    data: any
  ) => {
    if (type == "radio") {
      return (
        <TableStatus
          id={id}
          activateStatusUrl={activateStatusUrl}
          deactivateStatusUrl={deactivateStatusUrl}
          value={value}
          refreshData={refreshData}
        />
      );
    }
    if (type == "date") {
      return formateDate(value);
    }
    if (type == "boolean") {
      return (
        <span className="isolate inline-flex rounded-md shadow-sm">
          <Popover
            label="Email sent at"
            value={moment(data.processedAt).format("ddd, D MMM yyyy HH:mm:ss")}
            isCompleted={data?.emailAccount[0]?.email}
          />
          <Popover
            label="Email opened at"
            value={moment(data?.lastOpenedAt).format(
              "ddd, D MMM yyyy HH:mm:ss"
            )}
            isCompleted={data?.lastOpenedAt}
          />

          <Popover
            label="Email bounced at"
            value={moment(data?.lastBouncedAt).format(
              "ddd, D MMM yyyy HH:mm:ss"
            )}
            isCompleted={data?.lastBouncedAt}
          />
          <Popover
            label="Email replied at"
            value={moment(data?.lastRepliedAt).format(
              "ddd, D MMM yyyy HH:mm:ss"
            )}
            isCompleted={data?.lastRepliedAt}
          />
        </span>
      );
    }
    if (type === "status") {
      let bgColor = "";
      if (value === "draft") bgColor = "bg-[#F9C107]";
      if (value === "active") bgColor = "bg-[#3CA745]";
      if (value === "pause") bgColor = "bg-[#DC3545]";
      return (
        <button
          className={`inline-flex rounded-full ${bgColor} py-1 px-3 text-sm font-medium text-[#212B36] hover:bg-opacity-90`}>
          {value}
        </button>
      );
    }
    return value;
  };

  useEffect(() => {
    const handleScroll = () => {
      // Check if user has scrolled down enough to show the bottom-fixed div
      if (window.scrollY > 10) {
        // Adjust 100 to your preferred scroll threshold
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div className="mt-8">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {dataList && dataList.length > 0 && (
                <div className="w-100">
                  {/* <input
                    type="text"
                    onChange={onSearch && onSearch}
                    className="w-full rounded-md border border-stroke px-5 py-2.5 outline-none focus:border-primary dark:border-strokedark dark:bg-meta-4 dark:focus:border-primary"
                    placeholder="Search..."
                    value={searchValue}
                  /> */}
                  <Input
                    inputType="text"
                    onChange={onSearch && onSearch}
                    placeholder="Search..."
                    value={searchValue ? searchValue : ""}
                  />
                </div>
              )}
              {showRefreshBtn && dataList && dataList.length > 0 && (
                <div
                  className="hover:text-primary hover:bg-indigo-50 rounded-md cursor-pointer
        transition-colors group p-2">
                  <div className="mt-auto">
                    <IoIosRefresh
                      onClick={() => {
                        if (onRefresh) {
                          onRefresh();
                        }
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                {/* {selectedPeople.length > 0 && (
                  <div className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
                    <button
                      type="button"
                      className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">
                      Bulk edit
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">
                      Delete all
                    </button>
                  </div>
                )} */}
                {dataList && dataList.length > 0 && (
                  <table className="min-w-full table-fixed divide-y divide-gray">
                    <TableHeader
                      fields={fields}
                      toggleAll={toggleAll}
                      checked={checked}
                    />
                    <tbody className="divide-y divide-gray bg-white">
                      {dataList &&
                        dataList.length > 0 &&
                        dataList.map((account: any, index: number) => (
                          <tr
                            key={index}
                            className={
                              selectedPeople.includes(account)
                                ? "bg-gray-50"
                                : undefined
                            }>
                            <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                              {selectedPeople.includes(account) && (
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                              )}
                              {/* <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                            value={person.email}
                            checked={selectedPeople.includes(person)}
                            onChange={(e) =>
                              setSelectedPeople(
                                e.target.checked
                                  ? [...selectedPeople, person]
                                  : selectedPeople.filter((p) => p !== person)
                              )
                            }
                          /> */}
                            </td>
                            {fields.length &&
                              fields.map((field, i) => (
                                <td
                                  key={i}
                                  className={classNames(
                                    "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                                    selectedPeople.includes(account[field.key])
                                      ? "text-indigo-600"
                                      : "text-gray-900"
                                  )}>
                                  {displayTableValue(
                                    field.type,
                                    account[field.key],
                                    account._id,
                                    account
                                  )}
                                </td>
                              ))}

                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <TableAction
                                onView={() =>
                                  onView && onView(account._id, account.name)
                                }
                                onEdit={() =>
                                  onEdit && onEdit(account._id, account.name)
                                }
                                onDelete={() => onDelete && onDelete()}
                                deleteUrl={deleteUrl}
                                id={account._id}
                                permission={permission}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
                {!dataList ||
                  (dataList && dataList.length <= 0 && !isLoading && (
                    <div className="px-4 py-8">
                      <NoData
                        buttonName={title}
                        onAdd={() => onAdd && onAdd()}
                      />
                    </div>
                  ))}
                {isLoading && (
                  <div className="w-full">
                    <TableSkeletonLoading />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {isVisible && (
          <div className="z-10 bg-white fixed bottom-0 left-0 roght-0 w-full justify-between border-t border-stroke px-8 pt-3 pb-3 dark:border-strokedark">
            <TableFooter meta={meta} onPageChange={onPageChange} />
          </div>
        )}
      </div>
    </>
  );
};

export default Table;
