// Dependencies
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Custom Components
import Input from "../../components/input/input";
import PrimaryButton from "../../components/button/primary-button";

// Custom Hook
import { useAPI } from "../../hooks/useApi";

// i18n translation
import { useTranslation } from "react-i18next";

// Constants
import {
  API,
  DEFAULT_VALUE,
  INPUT_TYPE,
  ROUTES,
  TRANSLATATION_KEY
} from "../../utils/contants";

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>(DEFAULT_VALUE.EMPTY);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(DEFAULT_VALUE.TRUE);
  const { data, isLoading, callApi } = useAPI(
    API.ROUTE.REGISTER,
    API.METHOD.POST,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE,
    t(TRANSLATATION_KEY.REGISTER.API_SUCCESS_MSG)
  );

  useEffect(() => {
    if (data) {
      navigate(ROUTES.ONBOARD + email);
    }
  }, [data]);

  const onChangeEmail = (value: string) => {
    setIsEmailValid(true);
    setEmail(value);
  };

  const onRegisterClickHandler = () => {
    if (isLoading) return;
    if (!email) return setIsEmailValid(false);

    const payload = {
      email,
      timeZone: "Asia/Kolkata"
    };
    callApi(payload);
  };

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="lg:flex">
        <div className="hidden lg:flex items-center justify-center bg-indigo-100 flex-1 h-screen">
          <div className="py-12 bg-indigo-100 lg:bg-white flex justify-center lg:justify-start lg:px-12">
            <div className="cursor-pointer flex items-center">
              <div></div>
              <div className="text-2xl text-black tracking-wide ml-2 font-semibold">
                {t(TRANSLATATION_KEY.APP.NAME)}
              </div>
            </div>
          </div>
          <div className="max-w-xs transform duration-200 hover:scale-110 cursor-pointer"></div>
        </div>
        <div className="lg:w-2/3 flex justify-center items-center">
          <div className="w-full lg:w-2/3 px-12 sm:px-24 md:px-48 lg:px-12 xl:px-24 xl:max-w-2xl">
            <h2
              className="text-center text-xl text-black font-display font-semibold lg:text-left xl:text-2xl
                    xl:text-bold">
              {t(TRANSLATATION_KEY.REGISTER.FORM.TITLE)}
            </h2>
            <div className="mt-12">
              <form>
                <Input
                  label={t(TRANSLATATION_KEY.REGISTER.FORM.EMAIL_FIELD.LABEL)}
                  inputType={INPUT_TYPE.EMAIL}
                  placeholder={t(
                    TRANSLATATION_KEY.REGISTER.FORM.EMAIL_FIELD.PLACEHOLDER
                  )}
                  value={email}
                  onChange={onChangeEmail}
                  isValid={isEmailValid}
                  validationMessage={t(
                    TRANSLATATION_KEY.REGISTER.FORM.EMAIL_FIELD.VALIDATION_MSG
                  )}
                  disabled={isLoading}
                />
                <div className="mt-10">
                  <PrimaryButton
                    label={t(TRANSLATATION_KEY.REGISTER.BUTTON.SIGN_UP)}
                    onClick={onRegisterClickHandler}
                    isLoading={isLoading}
                  />
                </div>
              </form>
              <div className="mt-12 text-sm font-display font-semibold text-gray-700 text-center">
                {t(TRANSLATATION_KEY.REGISTER.BUTTON.ACCOUNT_EXIST_TEXT)}{" "}
                <Link
                  to={ROUTES.LOGIN}
                  className="cursor-pointer text-black hover:text-indigo-800">
                  {t(TRANSLATATION_KEY.REGISTER.BUTTON.SIGN_IN)}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
