import { useEffect, useState } from "react";
import ErrorNotificationIcon from "../../assets/svg/error-notification-icon";
import ModalCloseIcon from "../../assets/svg/modal-close-icon";
import SuccessNotificationIcon from "../../assets/svg/success-notification-icon";
import WarningNotificationIcon from "../../assets/svg/warning-notification-icon";
import { useStateSelector } from "../../reducers";
import { useAppDispatch } from "../../store/store";
import {
  NotificationReducerTypes,
  NotificationType
} from "./notification.reducer";

const Notification = () => {
  const dispatch = useAppDispatch();
  const [notificationsList, setNotifications] = useState<NotificationType[]>(
    []
  );
  const { notifications } = useStateSelector((state) => state.notification);
  useEffect(() => {
    if (notifications.length) {
      setNotifications(notifications);
    } else {
      setNotifications([]);
    }
    const timerIds: any[] = [];
    notifications.forEach((notification) => {
      const timerId: any = setTimeout(() => {
        // remove notification
        dispatch({
          type: NotificationReducerTypes.DELETE,
          payload: notification.id
        });
      }, 3000); // Auto close after 3 seconds
      timerIds.push(timerId);
    });

    return () => {
      timerIds.forEach((timerId) => clearTimeout(timerId));
    };
  }, [notifications]);
  const notification: any = {
    success: {
      icon: <SuccessNotificationIcon />,
      bgColor: "bg-[#34D399] dark:bg-[#1B1B24]",
      borderColor: "border-[#34D399]",
      color: "text-white shadow-green-400/10"
    },
    warning: {
      icon: <WarningNotificationIcon />,
      bgColor: "bg-warning dark:bg-[#1B1B24]",
      borderColor: "border-warning",
      color: "text-white shadow-warning-400/10"
    },
    error: {
      icon: <ErrorNotificationIcon />,
      bgColor: "bg-[#F87171] dark:bg-[#1B1B24]",
      borderColor: "border-[#F87171]",
      color: "text-white shadow-red-400/10"
    }
  };
  return (
    <div className="fixed top-5 right-5 z-40">
      {notificationsList.length ? (
        notificationsList.map((notify) => (
          <div
            key={notify.id}
            className={`max-w-lg my-1 rounded-lg border px-4 py-3 shadow-lg ${notification[notify.type].color} ${notification[notify.type].bgColor} ${notification[notify.type].borderColor}`}>
            <div className="flex items-center gap-">
              {notify.type !== "error" && (
                <span className={`h-8 w-full max-w-[36px] rounded-lg`}>
                  {notification[notify.type].icon}
                </span>
              )}
              <p className={`font-medium text-sm font-semibold`}>
                {notify.title}
              </p>
              <div className="ml-3">
                <ModalCloseIcon />
              </div>
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default Notification;
