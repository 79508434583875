// Dependencies
import { useState, useEffect } from "react";
import Papa from "papaparse";

// Components
import Select from "../../components/select/select";

// Icons
import Upload from "../../assets/svg/upload-icon";

// Hooks
import { useAPI } from "../../hooks/useApi";

// Constants
import {
  API,
  DEFAULT_VALUE,
  FILE_TYPE,
  IMPORT_LEAD_FILE_TYPE,
  IMPORT_LEAD_OPTION,
  INPUT_TYPE,
  MULTIPART_HEADER,
  TRANSLATATION_KEY
} from "../../utils/contants";

// i18n Translation
import { useTranslation } from "react-i18next";

// Types
import { CustomField } from "../../common-types/common-type";
import PrimaryButton from "../../components/button/primary-button";

const ImportLeadForm = ({
  customFields,
  closeModal
}: {
  customFields: CustomField[];
  closeModal: () => void;
}) => {
  const { t } = useTranslation();
  const { data, callApi } = useAPI(
    API.ROUTE.LEAD_IMPORT,
    API.METHOD.POST,
    MULTIPART_HEADER,
    DEFAULT_VALUE.TRUE,
    t(TRANSLATATION_KEY.LEADS.DRAWER.API_SUCCESS_MSG)
  );

  const [step, setStep] = useState(1);
  const [conflictAction, setConflictAction] = useState(
    IMPORT_LEAD_OPTION[0].name
  );
  const [csvFile, setCsvFile] = useState(DEFAULT_VALUE.NULL);
  const [parseCSV, setParsedCSV] = useState({
    headers: [],
    values: []
  });
  const [mappingConfig, setMappingConfig] = useState<any>({});

  useEffect(() => {
    // handle API response
    if (data) {
      closeModal();
    }
  }, [data]);

  const handleClose = () => {
    setCsvFile(DEFAULT_VALUE.NULL);
    setParsedCSV({ headers: [], values: [] });
    setStep(1);
    setMappingConfig({});
    closeModal();
  };

  const handleSave = (verifyLeads: string) => {
    const formData = new FormData();
    formData.append("file", csvFile ? csvFile : "");
    formData.append("conflictAction", conflictAction);
    formData.append("verifyLeads", verifyLeads);
    formData.append("mappingConfig", JSON.stringify(mappingConfig));
    callApi(formData);
  };

  const handleFileChange = (e: any) => {
    const fileSizeInMB = e.target.files[0].size / (1024 * 1024);
    const selectedFileType = e.target.files[0].type;
    if (selectedFileType === FILE_TYPE.CSV) {
      if (fileSizeInMB <= 10) {
        const file = e.target.files[0];
        setCsvFile(file);
        Papa.parse(file, {
          skipEmptyLines: true,
          header: true,
          complete: (results) => {
            const rowsArray: any = [];
            const valuesArray: any = [];
            results.data.map((d: any) => {
              rowsArray.push(Object.keys(d));
              valuesArray.push(Object.values(d));
            });

            // Filtered Column Names
            setParsedCSV({
              headers: rowsArray[0],
              values: valuesArray.length ? valuesArray[0] : []
            });
            e.target.files = DEFAULT_VALUE.NULL;
          }
        });
      } else {
        // Maximum File Size 10MB is allowed
      }
    } else {
      // Only CSV file is allowed
    }
  };

  const importCSVFileForm = () => {
    return (
      <div
        id="FileUpload"
        className="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5">
        <input
          type={INPUT_TYPE.FILE}
          className="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
          accept={IMPORT_LEAD_FILE_TYPE}
          onChange={handleFileChange}
        />
        <div className="flex flex-col items-center justify-center space-y-3">
          <span className="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark">
            <Upload />
          </span>
          <p>
            <span className="text-primary">
              {t(TRANSLATATION_KEY.LEADS.DRAWER.CLICK_TO_UPLOAD_LINK)}
            </span>{" "}
            {t(TRANSLATATION_KEY.LEADS.DRAWER.DRAG_AND_DROP)}
          </p>
          <p className="mt-1.5">{t(TRANSLATATION_KEY.LEADS.DRAWER.CSV)}</p>
          <p>{t(TRANSLATATION_KEY.LEADS.DRAWER.MAX_FILE_LIMIT)}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      <div className="w-full">
        {step === 1 && (
          <div className="w-full">
            {!csvFile && importCSVFileForm()}
            {csvFile &&
              customFields &&
              customFields.length &&
              customFields.map((field: CustomField, index: number) => (
                <div
                  className="py-2 border-b border-gray box-shadow"
                  key={field._id}>
                  <div className="flex">
                    <div className="flex-1 my-auto">{field.label}</div>
                    <div className="flex-1 text-start my-auto">
                      {parseCSV.values[index] || "-"}
                    </div>
                  </div>

                  <Select
                    label={t(TRANSLATATION_KEY.LEADS.DRAWER.MAP_YOUR_FIELD)}
                    options={parseCSV.headers}
                    value={
                      mappingConfig && mappingConfig[field._id]
                        ? mappingConfig[field._id]
                        : parseCSV.headers &&
                          parseCSV.headers.length > 0 &&
                          parseCSV.headers[0]
                    }
                    onChange={(value: string) =>
                      setMappingConfig({
                        ...mappingConfig,
                        [field._id]: value
                      })
                    }
                  />
                </div>
              ))}
          </div>
        )}
        {step === 2 && (
          <div className="w-full">
            <Select
              label={t(TRANSLATATION_KEY.LEADS.DRAWER.OPTION)}
              options={IMPORT_LEAD_OPTION}
              value={conflictAction}
              onChange={(value: string) => setConflictAction(value)}
            />
          </div>
        )}
      </div>
      <div className="flex justify-end gap-4.5 pt-2">
        <PrimaryButton
          label={t(TRANSLATATION_KEY.LEADS.BUTTON.CANCEL)}
          onClick={handleClose}
        />
        {step === 2 && (
          <PrimaryButton
            label={t(TRANSLATATION_KEY.LEADS.BUTTON.VERIFY_SAVE)}
            onClick={() => handleSave("1")}
          />
        )}
        <PrimaryButton
          label={
            step === 1 || step === 2
              ? t(TRANSLATATION_KEY.LEADS.BUTTON.NEXT)
              : t(TRANSLATATION_KEY.LEADS.BUTTON.SAVE)
          }
          onClick={() => (step === 1 ? setStep(step + 1) : handleSave("0"))}
        />
      </div>
    </div>
  );
};

export default ImportLeadForm;
