import { useEffect, useState } from "react";
import { useAPI } from "../../hooks/useApi";
import { API } from "../../utils/contants";

const TableStatus = ({
  activateStatusUrl,
  deactivateStatusUrl,
  id,
  value,
  refreshData
}: {
  activateStatusUrl?: string;
  deactivateStatusUrl?: string;
  id: number;
  value: string;
  refreshData?: () => void | undefined;
}) => {
  const [status, setStatus] = useState<string>("");
  const activateStatus = useAPI(
    `${activateStatusUrl}${id}`,
    API.METHOD.GET,
    null,
    true
  );
  const deactivateStatus = useAPI(
    `${deactivateStatusUrl}/${id}`,
    API.METHOD.GET,
    null,
    true
  );

  useEffect(() => {
    if (value) {
      setStatus(value);
    }
  }, [value]);

  useEffect(() => {
    if (activateStatus.data || deactivateStatus.data) {
      refreshData && refreshData();
    }
  }, [activateStatus.data, deactivateStatus.data]);

  return (
    <label className="inline-flex items-center me-5 cursor-pointer">
      <input
        type="checkbox"
        value={status}
        className="sr-only peer"
        checked={status === "active"}
        onChange={(e: any) => {
          if (e.target.checked) {
            activateStatus.callApi();
          } else {
            deactivateStatus.callApi();
          }
        }}
      />
      <div className="relative w-11 h-6 bg-[#e5e7eb] peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-[#374151] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-[#d1d5db] after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#4b5563] peer-checked:bg-blue-600"></div>
    </label>
  );
};

export default TableStatus;
