import { ReducerActionType } from "../../common-types/reducer-type";

export type NotificationType = {
  title: string;
  subTitle: string;
  type: "error" | "success" | "warning";
  id: number;
};

export type NotificationState = {
  notifications: any[];
};

const initialState: NotificationState = {
  notifications: []
};

export enum NotificationReducerTypes {
  ADD = "ADD_NOTIFICATION",
  DELETE = "DELETE_NOTIFICATION"
}

export const notificationReducer = (
  state = initialState,
  action: ReducerActionType
) => {
  switch (action.type) {
    case NotificationReducerTypes.ADD:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications]
      };

    case NotificationReducerTypes.DELETE:
      return {
        ...state,
        notifications: state.notifications.filter(
          (v) => v.id !== action.payload
        )
      };

    default:
      return state;
  }
};
