// Dependencies
import { useEffect, useState } from "react";

// Custom Hook
import { useAPI } from "../../hooks/useApi";

// Constant
import { API, TABLE } from "../../utils/contants";

// Components
import PrimaryButton from "../../components/button/primary-button";
import { PlusIcon } from "@heroicons/react/20/solid";

const AddEmailToSequence = ({
  closeModal,
  id
}: {
  closeModal: () => void;
  id: string | undefined;
}) => {
  const [leadsArray, setLeads] = useState<any[]>([]);
  const [page] = useState<number>(1);
  const [selectedProspects, addProspectToArray] = useState<any>([]);
  const [search, setSearch] = useState("");

  // API Hook
  const leads = useAPI(
    `${API.ROUTE.EMAIL_ACCOUNT}?page=${page}&pageSize=${TABLE.PAGE_SIZE}`,
    API.METHOD.GET,
    null,
    true
  );

  useEffect(() => {
    leads.callApi();
  }, [page]);

  useEffect(() => {
    if (leads.data) {
      const { payload }: any = leads.data;
      setLeads(payload?.emailAccounts);
    }
  }, [leads.data]);

  const { data, isLoading, callApi } = useAPI(
    `sequence/${id}/settings/add-email-accounts`,
    API.METHOD.PATCH,
    null,
    true,
    "Email account added to campaign."
  );

  useEffect(() => {
    // handle API response
    if (data) {
      closeModal();
    }
  }, [data]);

  const onFormSubmit = () => {
    const payload = {
      emailAccountIds: selectedProspects
    };
    callApi(payload);
  };

  const selectProspects = (id: string) => {
    const isExist = selectedProspects.find((pros: string) => pros === id);
    if (!isExist) {
      addProspectToArray([...selectedProspects, id]);
    }
  };

  const getEmailById = (id: string) => {
    const account = leadsArray.find((lead: any) => lead._id === id);
    if (account) {
      return account.email;
    }
    return "-";
  };

  const removeSelectedProspects = (id: string) => {
    const newProspectArray = selectedProspects.filter(
      (pros: string) => pros !== id
    );
    addProspectToArray(newProspectArray);
  };

  return (
    <>
      <div className="mx-auto max-w-md sm:max-w-3xl">
        <div>
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 48 48"
              aria-hidden="true">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
            <h2 className="mt-2 text-lg font-medium text-gray-900">
              Add email accounts
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Add multiple email accounts to start sending emails from a
              campaign at a faster rate
            </p>
          </div>
          <div className="mt-6 sm:flex sm:items-center w-full">
            <input
              type="text"
              name=""
              id="email"
              placeholder="Search by email"
              onChange={(e: any) => setSearch(e.target.value)}
              value={search}
              autoComplete="organization"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="py-4">
          {selectedProspects.length > 0 &&
            selectedProspects.map((pros: string, index: number) => (
              <span
                key={index}
                className="inline-flex items-center rounded-full bg-indigo-100 py-0.5 pl-2 pr-0.5 text-xs font-medium text-indigo-700">
                {getEmailById(pros)}
                <button
                  type="button"
                  onClick={() => removeSelectedProspects(pros)}
                  className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500 focus:text-white focus:outline-none">
                  <span className="sr-only">Remove small option</span>
                  <svg
                    className="h-2 w-2"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 8 8">
                    <path
                      strokeLinecap="round"
                      strokeWidth="1.5"
                      d="M1 1l6 6m0-6L1 7"
                    />
                  </svg>
                </button>
              </span>
            ))}
        </div>
        <div className="mt-10">
          <h3 className="text-sm font-medium text-gray-500">
            Recommended email accounts
          </h3>
          <ul
            role="list"
            className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {leadsArray.length > 0 &&
              leadsArray.map((person: any, personIdx: number) => (
                <li key={personIdx}>
                  <button
                    type="button"
                    onClick={() => selectProspects(person._id)}
                    className="group flex w-full items-center justify-between space-x-3 rounded-full p-2 text-left shadow-sm hover:bg-[#e2e8f0] focus:outline-none ring-1 ring-gray-300 focus:ring-offset-2">
                    <span className="flex min-w-0 flex-1 items-center space-x-3">
                      <span className="block flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />
                      </span>
                      <span className="block min-w-0 flex-1">
                        <span className="block truncate text-sm font-medium text-gray-900">
                          {person.name}
                        </span>
                        <span className="block truncate text-sm font-medium text-gray-500">
                          {person.email}
                        </span>
                      </span>
                    </span>
                    <span className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
                      <PlusIcon
                        className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </span>
                  </button>
                </li>
              ))}
          </ul>
        </div>
        <div className="-mx-3 flex flex-wrap gap-y-4 justify-end mt-3">
          <div className="px-3 py-3">
            <PrimaryButton
              label={"Save"}
              onClick={onFormSubmit}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEmailToSequence;
