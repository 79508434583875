// Dependencies
import { useState } from "react";

// Components
import GeneralSetting from "./general-setting";

// Constants
import { TABS } from "../../../utils/contants";

const EditEmailAccount = () => {
  const [activeTab, setActiveTab] = useState<string>(
    TABS.EMAIL_ACCOUNTS.GENERAL
  );
  return (
    <div className="rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="mb-6 flex flex-wrap gap-5 border-b border-stroke dark:border-strokedark sm:gap-10">
        <a
          className={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base ${activeTab == "general" ? "text-primary border-primary" : "border-transparent"}`}
          onClick={() => setActiveTab(TABS.EMAIL_ACCOUNTS.GENERAL)}>
          General Settings
        </a>
        <a
          className={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base ${activeTab == "sending" ? "text-primary border-primary" : "border-transparent"}`}
          onClick={() => setActiveTab(TABS.EMAIL_ACCOUNTS.SENDING)}>
          Sending Settings
        </a>
      </div>
      <div>
        {activeTab == TABS.EMAIL_ACCOUNTS.GENERAL && (
          <div className="leading-relaxed block">
            <GeneralSetting />
          </div>
        )}
        {activeTab == TABS.EMAIL_ACCOUNTS.SENDING && (
          <div className="leading-relaxed block">sending</div>
        )}
      </div>
    </div>
  );
};

export default EditEmailAccount;
