// Dependencies
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

// Custom Hook
import { useAPI } from "../../hooks/useApi";
import { getMyIpAddress } from "../../hooks/getMyIpAddress";

// i18n translation
import { useTranslation } from "react-i18next";

// Components
import Input from "../../components/input/input";
import PrimaryButton from "../../components/button/primary-button";

// Constant
import {
  ROUTES,
  API,
  LOCAL_STORAGE,
  INPUT_TYPE,
  TRANSLATATION_KEY,
  DEFAULT_VALUE
} from "../../utils/contants";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>(DEFAULT_VALUE.EMPTY);
  const [password, setPassword] = useState<string>(DEFAULT_VALUE.EMPTY);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(DEFAULT_VALUE.TRUE);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(
    DEFAULT_VALUE.TRUE
  );
  const { data, isLoading, error, callApi } = useAPI(
    API.ROUTE.LOGIN,
    API.METHOD.POST,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE,
    t(TRANSLATATION_KEY.LOGIN.API_SUCCESS_MSG)
  );
  const { ipAddress } = getMyIpAddress();

  useEffect(() => {
    if (data) {
      const { payload }: any = data;
      const authToken: string = payload.jwtToken;
      localStorage.setItem(LOCAL_STORAGE.TOKEN, authToken.toString());
      navigate(ROUTES.SEQUENCE);
    }
  }, [data, error]);

  const validateForm = () => {
    let isValid = true;
    if (isLoading) {
      isValid = false;
    }
    if (!email) {
      setIsEmailValid(false);
      isValid = false;
    }
    if (!password) {
      setIsPasswordValid(false);
      isValid = false;
    }
    return isValid;
  };

  const onLoginClickHandler = () => {
    if (!validateForm()) {
      return;
    }

    setIsEmailValid(true);
    setIsPasswordValid(true);
    const payload = {
      email,
      password,
      ip: ipAddress
    };
    callApi(payload);
  };

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="lg:flex">
        <div className="hidden lg:flex items-center justify-center bg-indigo-100 flex-1 h-screen">
          <div className="py-12 bg-indigo-100 lg:bg-white flex justify-center lg:justify-start lg:px-12">
            <div className="cursor-pointer flex items-center">
              <div></div>
              <div className="text-2xl text-black tracking-wide ml-2 font-semibold">
                {t(TRANSLATATION_KEY.APP.NAME)}
              </div>
            </div>
          </div>
          <div className="max-w-xs transform duration-200 hover:scale-110 cursor-pointer"></div>
        </div>
        <div className="lg:w-2/3 flex justify-center items-center">
          <div className="w-full lg:w-2/3 px-12 sm:px-24 md:px-48 lg:px-12 xl:px-24 xl:max-w-2xl">
            <h2
              className="text-center text-xl text-black-900 font-display font-semibold lg:text-left xl:text-2xl
                    xl:text-bold">
              {t(TRANSLATATION_KEY.LOGIN.FORM_TITLE)}
            </h2>
            <div className="mt-12">
              <form>
                <Input
                  label={t(TRANSLATATION_KEY.LOGIN.EMAIL_FIELD.LABEL)}
                  inputType={INPUT_TYPE.EMAIL}
                  placeholder={t(
                    TRANSLATATION_KEY.LOGIN.EMAIL_FIELD.PLACEHOLDER
                  )}
                  value={email}
                  onChange={(value: string) => setEmail(value)}
                  isValid={isEmailValid}
                  validationMessage={t(
                    TRANSLATATION_KEY.LOGIN.EMAIL_FIELD.VALIDATION_MSG
                  )}
                  disabled={isLoading}
                />
                <Input
                  label={t(TRANSLATATION_KEY.LOGIN.PASSWORD_FIELD.LABEL)}
                  inputType={INPUT_TYPE.PASSWORD}
                  placeholder={t(
                    TRANSLATATION_KEY.LOGIN.PASSWORD_FIELD.PLACEHOLDER
                  )}
                  value={password}
                  onChange={(value: string) => setPassword(value)}
                  isValid={isPasswordValid}
                  validationMessage={t(
                    TRANSLATATION_KEY.LOGIN.PASSWORD_FIELD.VALIDATION_MSG
                  )}
                  disabled={isLoading}
                />
                <div className="mt-10">
                  <PrimaryButton
                    label={t(TRANSLATATION_KEY.LOGIN.BUTTON.SIGN_IN)}
                    onClick={onLoginClickHandler}
                    isLoading={isLoading}
                  />
                </div>
              </form>
              <div className="mt-12 text-sm font-display font-semibold text-gray-700 text-center">
                {t(TRANSLATATION_KEY.LOGIN.DONT_HAVE_ACCOUNT_LABEL)}{" "}
                <Link
                  to={ROUTES.REGISTER}
                  className="cursor-pointer text-black hover:text-indigo-800">
                  {t(TRANSLATATION_KEY.LOGIN.BUTTON.SIGN_UP)}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
