// Dependencies
import { LineChart, DonutChart, Legend, Card } from "@tremor/react";
import { Select, SelectItem } from "@tremor/react";
// Components

const OverallReport = () => {
  const chartdata2 = [
    {
      date: "Jan 22",
      SolarPanels: 2890,
      Inverters: 2338
    },
    {
      date: "Feb 22",
      SolarPanels: 2756,
      Inverters: 2103
    },
    {
      date: "Mar 22",
      SolarPanels: 3322,
      Inverters: 2194
    },
    {
      date: "Apr 22",
      SolarPanels: 3470,
      Inverters: 2108
    },
    {
      date: "May 22",
      SolarPanels: 3475,
      Inverters: 1812
    },
    {
      date: "Jun 22",
      SolarPanels: 3129,
      Inverters: 1726
    },
    {
      date: "Jul 22",
      SolarPanels: 3490,
      Inverters: 1982
    },
    {
      date: "Aug 22",
      SolarPanels: 2903,
      Inverters: 2012
    },
    {
      date: "Sep 22",
      SolarPanels: 2643,
      Inverters: 2342
    },
    {
      date: "Oct 22",
      SolarPanels: 2837,
      Inverters: 2473
    },
    {
      date: "Nov 22",
      SolarPanels: 2954,
      Inverters: 3848
    },
    {
      date: "Dec 22",
      SolarPanels: 3239,
      Inverters: 3736
    }
  ];

  const sales = [
    {
      name: "New York",
      sales: 980
    },
    {
      name: "London",
      sales: 456
    },
    {
      name: "Hong Kong",
      sales: 390
    },
    {
      name: "San Francisco",
      sales: 240
    },
    {
      name: "Singapore",
      sales: 190
    }
  ];

  const valueFormatter = (number: number) =>
    `$ ${Intl.NumberFormat("us").format(number).toString()}`;

  return (
    <div className="relative p-4 md:p-8">
      <div className="relative pb-5 sm:pb-0">
        <Card className="w-auto">
          <div className="font-mono text-sm text-slate-500">
            Select Campaign
          </div>
          <Select defaultValue="1" className="w-50">
            <SelectItem value="1">Option One</SelectItem>
            <SelectItem value="2">Option Two</SelectItem>
            <SelectItem value="3">Option Three</SelectItem>
          </Select>
        </Card>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-4">
          <div className="col-span-1 flex flex-col">
            <Card className="max-w-4xl justify-center align-center text-center m-auto">
              <span className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                Total Requests
              </span>
              <p className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                6,568
              </p>
              <DonutChart
                data={sales}
                category="sales"
                index="name"
                valueFormatter={valueFormatter}
                colors={["blue", "cyan", "indigo", "violet", "fuchsia"]}
                className="w-auto h-40"
              />
              <Legend
                categories={[
                  "New York",
                  "London",
                  "Hong Kong",
                  "San Francisco",
                  "Singapore"
                ]}
                colors={["blue", "cyan", "indigo", "violet", "fuchsia"]}
                className="max-w-xs"
              />
            </Card>
          </div>
          <div className="col-span-1 flex flex-col">
            <Card className="max-w-4xl justify-center align-center text-center">
              <span className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                Total Requests
              </span>
              <p className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                6,568
              </p>
              <LineChart
                className="mt-4 h-48"
                data={chartdata2}
                index="date"
                yAxisWidth={65}
                categories={["SolarPanels", "Inverters"]}
                colors={["indigo", "cyan"]}
                valueFormatter={valueFormatter}
              />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverallReport;
