import {
  RiShapesFill,
  RiLineChartLine,
  RiMailSettingsLine,
  RiCalendarScheduleFill,
  RiUserStarLine,
  RiKeyboardBoxFill
} from "@remixicon/react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@tremor/react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const sidebarNavigation = [
  { name: "Campaign", href: "/sequence", icon: RiShapesFill, current: true },
  {
    name: "Settings",
    href: "/settings",
    icon: RiMailSettingsLine,
    current: false
  },
  {
    name: "Schedules",
    href: "/schedule",
    icon: RiCalendarScheduleFill,
    current: false
  },
  { name: "Prospects", href: "/leads", icon: RiUserStarLine, current: false },
  {
    name: "Custom Fields",
    href: "/custom-fields",
    icon: RiKeyboardBoxFill,
    current: false
  },
  {
    name: "Reports",
    href: "/report",
    icon: RiLineChartLine,
    current: false
  }
];

const AdminSidebar = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const isActiveLink = (link: string) => {
    return location.pathname === link;
  };

  return (
    <nav
      aria-label="Sidebar"
      className="hidden pt-8 md:block md:flex-shrink-0 md:overflow-y-auto bg-[#f3f4f6]">
      {/* <h1 className="items-center text-center justify-center text-[#9ca3af]">
        Menus
      </h1> */}
      <div className="relative pt-2 flex w-20 flex-col space-y-3 items-center justify-center">
        {sidebarNavigation.map((item) => (
          <>
            <div
              data-popover
              id="popover-right"
              role="tooltip"
              className={`absolute z-10 ${open ? "invisible" : "invisible"} inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800`}>
              <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                <h3 className="font-semibold text-gray-900 dark:text-white">
                  Popover right
                </h3>
              </div>
              <div className="px-3 py-2">
                <p>And heres some amazing content. Its very engaging. Right?</p>
              </div>
              <div data-popper-arrow></div>
            </div>
            <Link
              key={item.name}
              to={item.href}
              onMouseOver={() => setOpen(true)}
              onMouseOut={() =>
                setTimeout(() => {
                  setOpen(false);
                }, 2000)
              }
              className={classNames(
                isActiveLink(item.href)
                  ? "bg-[#0f172a] text-white"
                  : "text-[#0f172a] hover:text-white hover:bg-[#0f172a]",
                "flex-shrink-0 inline-flex items-center justify-center h-9 w-9 rounded-lg"
              )}>
              <span className="sr-only">{item.name}</span>
              <Icon
                size="md"
                icon={item.icon}
                color={isActiveLink(item.href) ? "gray" : "neutral"}
              />
            </Link>
          </>
        ))}
      </div>
    </nav>
  );
};

export default AdminSidebar;
