import { ReactNode } from "react";
// SVG Icon
import ModalCloseIcon from "../../assets/svg/modal-close-icon";

type ModalProps = {
  title: string;
  showModal: boolean;
  onCloseModal: () => void;
  children: ReactNode;
  width?: "Max" | "Min" | "MaxPro";
};

const Modal = ({
  title,
  showModal,
  onCloseModal,
  width = "Min",
  children
}: ModalProps) => {
  console.log(width);
  return (
    <div
      className={`fixed left-0 top-0 z-999999 flex h-full h-auto w-full items-center justify-center bg-black/90 px-4 py-5 ${showModal ? "block" : "hidden"}`}>
      <div
        className={`p-2 relative w-full ${width == "Max" ? "max-w-4xl" : width == "MaxPro" ? "w-full" : "max-w-142.5"} rounded-lg bg-white dark:bg-boxdark`}>
        <button
          onClick={onCloseModal}
          className="right-6 absolute top-6 flex h-7 w-7 items-center justify-center rounded-full text-white bg-boxdark transition hover:bg-boxdark hover:text-boxdark">
          <ModalCloseIcon />
        </button>

        <div className="w-full text-start">
          <span className="pt-4 pl-6 mb-6 font-bold text-lg inline-block w-full rounded bg-white">
            {title}
          </span>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
