import { BsPlusLg } from "react-icons/bs";
import Upload from "../../assets/svg/upload-icon";
import PrimaryButton from "../button/primary-button";

type BreadcrumbProps = {
  pageName: string;
  showAddBtn?: boolean;
  onBtnClick?: (value: boolean) => void;
  onUploadBtnClick?: () => void;
  showImportBtn?: boolean;
};
const Breadcrumb = ({
  pageName,
  showAddBtn = false,
  onBtnClick,
  onUploadBtnClick,
  showImportBtn = false
}: BreadcrumbProps) => {
  return (
    <>
      <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between mt-8">
        <h2 className="text-title-md2 font-semibold text-black dark:text-white">
          {pageName}
        </h2>
        <div className="flex gap-3">
          {showImportBtn && (
            <span
              onClick={() => onUploadBtnClick && onUploadBtnClick()}
              className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border border-stroke bg-grey dark:border-strokedark dark:bg-boxdark">
              <Upload />
            </span>
          )}

          {showAddBtn && (
            <PrimaryButton
              onClick={() => onBtnClick && onBtnClick(false)}
              label={`Create ${pageName}`}
              icon={<BsPlusLg />}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
