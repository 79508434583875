import { useAPI } from "../../hooks/useApi";
import { useEffect, Fragment, useState } from "react";
import { BellIcon } from "@heroicons/react/24/outline";
import { Transition } from "@headlessui/react";
import { API } from "../../utils/contants";
import { Popover } from "@headlessui/react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type NotificationType = {
  templateMessage: string;
  _id: string;
  notificationtype: string;
  isSeen: boolean;
  createdAt: string;
};

const DropdownNotification = () => {
  const [notify, setNotifications] = useState([]);
  const notifications: any = useAPI(
    `${API.ROUTE.NOTIFICATIONS}?page=${1}&pageSize=${50}`,
    API.METHOD.GET,
    null,
    false
  );
  useEffect(() => {
    const intervalId = setInterval(() => notifications.callApi(), 10000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (notifications.data) {
      const notify = notifications?.data?.payload?.notifications;
      setNotifications(notify);
    }
  }, [notifications.data]);
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? "text-gray-900" : "text-gray-500",
              "group"
            )}>
            <a
              href="#"
              className="-mx-1 rounded-full p-1 text-gray-400 hover:text-gray-500">
              <span className="sr-only">View notifications</span>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </a>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <Popover.Panel className="absolute left-0 z-10 mt-3 max-w-xs -translate-x-3/4 transform px-2 sm:px-0">
              <div className="rounded-lg shadow-lg">
                <div className="bg-[#f9fafb] px-5 py-5 ">
                  <div>
                    <div className="flex flex-wrap text-sm">
                      <h3 className="flex-1 text-sm text-gray-500">
                        Notifications
                      </h3>
                      <a
                        href="#"
                        className="font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500">
                        Mark all as viewed
                        <span aria-hidden="true"> &rarr;</span>
                      </a>
                    </div>
                    <ul
                      role="list"
                      className="overflow-scroll h-90 mt-4 bg-white space-y-2 divide-[#e2e8f0] divide-y">
                      {notify.map((notification: NotificationType) => (
                        <li
                          key={notification._id}
                          className="p-4 truncate text-sm">
                          <a
                            href="#"
                            className="font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-700"
                            dangerouslySetInnerHTML={{
                              __html: notification.templateMessage
                            }}
                          />
                          <p className="text-xs text-right text-[#64748b] pt-4">
                            {notification.createdAt}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default DropdownNotification;
