const Logo = () => {
  return (
    <div className="absolute inset-y-0 left-[0.2rem] md:flex-shrink-0">
      <a
        href="#"
        className="flex h-14 w-14 items-center justify-center bg-[#f3f4f6] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white md:w-20">
        <img
          className="h-8 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=black"
          alt="Your Company"
        />
      </a>
    </div>
  );
};

export default Logo;
