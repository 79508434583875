const TableSkeletonLoading = () => {
  return (
    <div className="w-full mx-auto">
      {[1, 2, 3].map((num: any) => (
        <div
          key={num}
          className="animate-pulse border-t border-stroke py-4.5 px-4 dark:border-strokedark">
          <div className=" flex space-x-4">
            <div className="flex-1 space-y-6 py-1 m-auto">
              <div className="h-2 w-32 bg-slate-700 rounded"></div>
            </div>
            <div className="flex-1 space-y-6 py-1 m-auto">
              <div className="h-2 w-32 bg-slate-700 rounded"></div>
            </div>
            <div className="flex-1 space-y-6 py-1 m-auto">
              <div className="h-2 w-32 bg-slate-700 rounded"></div>
            </div>
            <div className="">
              <div className="rounded-full bg-slate-700 h-4 w-4"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TableSkeletonLoading;
