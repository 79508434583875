type FieldProps = {
  key: string | null;
  label: string;
};

const TableHeader = ({
  fields
  // checked,
  // toggleAll
}: {
  fields: FieldProps[];
  toggleAll?: () => void;
  checked?: boolean;
}) => {
  return (
    <thead className="bg-gray-50">
      <tr>
        <th></th>
        {fields.length &&
          fields.map((field: FieldProps, index: number) => (
            <th
              key={index}
              scope="col"
              className={`${index === 0 && "min-w-[12rem]"} py-3.5 pr-3 text-left text-sm font-semibold text-gray-900`}>
              {field.label}
            </th>
          ))}
        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
          <span className="sr-only">Edit</span>
        </th>
      </tr>
    </thead>
  );
};

export default TableHeader;
