import { useState } from "react";
import { Outlet } from "react-router-dom";
import AdminHeader from "../AdminHeader/AdminHeader";
import AdminSidebar from "../AdminSidebar/AdminSidebar";

export const AdminLayout = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div className="flex h-screen overflow-scroll flex-col bg-[#f3f4f6]">
      <AdminHeader
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />
      <div className="flex min-h-0 flex-1 overflow-hidden">
        <AdminSidebar />

        <main className="min-w-0 flex-1 lg:flex bg-white rounded-tl-3xl">
          <section
            aria-labelledby="primary-heading"
            className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
            <Outlet />
          </section>
        </main>
      </div>
    </div>
  );
};
