import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

// Locales Languages
import translationEN from "./locales/en/translation.json";

const resources = {
  en: {
    translation: translationEN
  }
};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  resources,
  lng: "en",
  debug: true,
  fallbackLng: "en"
});

export default i18n;
