// Dependencies
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Components
import Table from "../../components/table/table";
import AddProspectsToSequence from "./add-prospects-to-sequence";

// Custom Hook
import { useAPI } from "../../hooks/useApi";

// i18n Translation
import { useTranslation } from "react-i18next";

// Constants
import {
  API,
  DEFAULT_VALUE,
  DRAWER_WIDTH,
  SEQUENCE_PROSPECTS_TABLE_FIELDS,
  TABLE,
  TRANSLATATION_KEY
} from "../../utils/contants";

// Types
import { DrawerWidthType } from "../../common-types/common-type";
import Modal from "../../components/modal/modal";

const SequenceProspects = ({ sequence }: { sequence: any }) => {
  const { t } = useTranslation();
  const [steps, setSteps] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [showSmtpImapModal, setShowSmtpImapModal] = useState<boolean>(
    DEFAULT_VALUE.FALSE
  );
  const { id } = useParams();
  const { data, isLoading, callApi } = useAPI(
    `${API.ROUTE.SEQUENCE}/${id}/leads?page=${page}&pageSize=${TABLE.PAGE_SIZE}`,
    API.METHOD.GET,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE
  );

  useEffect(() => {
    callApi();
  }, [page]);

  useEffect(() => {
    if (data) {
      const { payload }: any = data;
      if (payload?.sequenceLeads.length > 0) {
        const mapSteps = payload?.sequenceLeads.map((step: any) => {
          return { ...step, leadEmail: step.lead[0].email };
        });
        setSteps(mapSteps);
      }
    }
  }, [data]);

  const toggleModalHandler = () => {
    setShowSmtpImapModal(!showSmtpImapModal);
  };

  const changePage = (page: number) => {
    setPage(page);
  };

  return (
    <>
      <Modal
        title={t(TRANSLATATION_KEY.SEQUENCE.DRAWER.ADD_PROSPECTS_TITLE)}
        showModal={showSmtpImapModal}
        width={DRAWER_WIDTH.MAX as DrawerWidthType}
        onCloseModal={toggleModalHandler}>
        <AddProspectsToSequence
          id={id}
          sequence={sequence}
          closeModal={() => {
            toggleModalHandler();
            callApi();
          }}
        />
      </Modal>

      <div className="flex flex-col gap-10">
        <Table
          meta={DEFAULT_VALUE.NULL}
          page={page}
          pageSize={TABLE.PAGE_SIZE}
          onPageChange={changePage}
          fields={SEQUENCE_PROSPECTS_TABLE_FIELDS}
          dataList={steps}
          onEdit={() => {}}
          title={t(TRANSLATATION_KEY.SEQUENCE.LEADS_TABLE_TITLE)}
          onAdd={toggleModalHandler}
          isLoading={isLoading}
          showRefreshBtn={DEFAULT_VALUE.TRUE}
          onRefresh={callApi}
        />
      </div>
    </>
  );
};

export default SequenceProspects;
