// Dependencies
import { useEffect, useState } from "react";

// Custom Hook
import { useAPI } from "../../hooks/useApi";

// Components
import Input from "../../components/input/input";
import PrimaryButton from "../../components/button/primary-button";
import Select from "../../components/select/select";

// i18n translation
import { useTranslation } from "react-i18next";

// Constants
import {
  API,
  DEFAULT_VALUE,
  INPUT_TYPE,
  TRANSLATATION_KEY
} from "../../utils/contants";

// Types
import { InputProps } from "../../common-types/auth-types";

const typeOptions: string[] = ["text"];

const AddCustomField = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation();
  const [label, setLabel] = useState<InputProps>({
    value: DEFAULT_VALUE.EMPTY,
    isValid: DEFAULT_VALUE.TRUE
  });
  const [type, setType] = useState<InputProps>({
    value: typeOptions[0],
    isValid: DEFAULT_VALUE.TRUE
  });

  // API Hook
  const { data, isLoading, callApi } = useAPI(
    API.ROUTE.CUSTOM_FIELD,
    API.METHOD.POST,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE
  );

  useEffect(() => {
    // handle API response
    if (data) {
      closeModal();
    }
  }, [data]);

  const onFormSubmit = () => {
    // validate form

    // submit form
    const payload = {
      label: label.value
    };
    callApi(payload);
  };

  return (
    <div className="w-full">
      <div className="rounded-md dark:bg-boxdark">
        <div className="">
          <div className="items-center justify-between">
            <form>
              <div>
                <Input
                  label={t(
                    TRANSLATATION_KEY.CUSTOM_FIELD.FORM.LABEL_FIELD.LABEL
                  )}
                  inputType={INPUT_TYPE.TEXT}
                  placeholder={t(
                    TRANSLATATION_KEY.CUSTOM_FIELD.FORM.LABEL_FIELD.PLACEHOLDER
                  )}
                  value={label.value}
                  onChange={(value: string) => setLabel({ ...label, value })}
                  isValid={label.isValid}
                  validationMessage={t(
                    TRANSLATATION_KEY.CUSTOM_FIELD.FORM.LABEL_FIELD
                      .VALIDATION_MSG
                  )}
                />

                <div className="mb-4.5">
                  <Select
                    label={t(
                      TRANSLATATION_KEY.CUSTOM_FIELD.FORM.TYPE_FIELD.LABEL
                    )}
                    options={typeOptions}
                    value={type.value}
                    onChange={(value: string) => setType({ ...type, value })}
                  />
                  {!type.isValid && (
                    <p className="text-sm text-red-500">
                      {t(
                        TRANSLATATION_KEY.CUSTOM_FIELD.FORM.TYPE_FIELD
                          .VALIDATION_MSG
                      )}
                    </p>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="-mx-3 flex flex-wrap gap-y-4 justify-end">
        <div className="px-3 py-3">
          <PrimaryButton
            label={t(TRANSLATATION_KEY.CUSTOM_FIELD.BUTTON.SUBMIT)}
            onClick={onFormSubmit}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default AddCustomField;
