// Dependencies
import { useEffect, useState } from "react";

// Custom Hook
import { useAPI } from "../../hooks/useApi";

// i18n Translation
import { useTranslation } from "react-i18next";

// Constants
import {
  API,
  DEFAULT_VALUE,
  INPUT_TYPE,
  TIMEZONE_OPTIONS,
  TRANSLATATION_KEY
} from "../../utils/contants";

// Components
import PrimaryButton from "../../components/button/primary-button";
import Input from "../../components/input/input";
import Select from "../../components/select/select";

// Custom styling
import "./schedule.css";

// Types
import { TimeSlot } from "../../common-types/common-type";

const AddScheduleDrawer = ({
  closeModal,
  editId,
  editData
}: {
  closeModal: () => void;
  editId: any;
  editData: any;
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>(DEFAULT_VALUE.EMPTY);
  const [timezone, setTimezone] = useState<string>(TIMEZONE_OPTIONS[0]);
  const convertSecToHr = (time: number) => {
    return Math.floor(time / 3600);
  };

  const convertSecToMin = (time: number) => {
    return Math.floor((time % 3600) / 60);
  };
  const [timeSlot, setTimeSlots] = useState<TimeSlot[]>([
    {
      label: "Monday",
      selected: DEFAULT_VALUE.TRUE,
      day: 1,
      schedules: [
        {
          start: {
            hour: convertSecToHr(32400),
            minute: convertSecToMin(64800)
          },
          end: {
            hour: convertSecToHr(32400),
            minute: convertSecToMin(64800)
          }
        }
      ]
    },
    {
      label: "Tuesday",
      selected: DEFAULT_VALUE.TRUE,
      day: 2,
      schedules: [
        {
          start: {
            hour: convertSecToHr(32400),
            minute: convertSecToMin(64800)
          },
          end: {
            hour: convertSecToHr(32400),
            minute: convertSecToMin(64800)
          }
        }
      ]
    },
    {
      label: "Wednesday",
      selected: DEFAULT_VALUE.TRUE,
      day: 3,
      schedules: [
        {
          start: {
            hour: convertSecToHr(32400),
            minute: convertSecToMin(64800)
          },
          end: {
            hour: convertSecToHr(32400),
            minute: convertSecToMin(64800)
          }
        }
      ]
    },
    {
      label: "Thursday",
      selected: DEFAULT_VALUE.TRUE,
      day: 4,
      schedules: [
        {
          start: {
            hour: convertSecToHr(32400),
            minute: convertSecToMin(64800)
          },
          end: {
            hour: convertSecToHr(32400),
            minute: convertSecToMin(64800)
          }
        }
      ]
    },
    {
      label: "Friday",
      selected: DEFAULT_VALUE.TRUE,
      day: 5,
      schedules: [
        {
          start: {
            hour: convertSecToHr(32400),
            minute: convertSecToMin(64800)
          },
          end: {
            hour: convertSecToHr(32400),
            minute: convertSecToMin(64800)
          }
        }
      ]
    },
    {
      label: "Saturday",
      selected: DEFAULT_VALUE.FALSE,
      day: 6,
      schedules: [
        {
          start: {
            hour: convertSecToHr(32400),
            minute: convertSecToMin(64800)
          },
          end: {
            hour: convertSecToHr(32400),
            minute: convertSecToMin(64800)
          }
        }
      ]
    },
    {
      label: "Sunday",
      selected: DEFAULT_VALUE.FALSE,
      day: 0,
      schedules: [
        {
          start: {
            hour: convertSecToHr(32400),
            minute: convertSecToMin(64800)
          },
          end: {
            hour: convertSecToHr(32400),
            minute: convertSecToMin(64800)
          }
        }
      ]
    }
  ]);

  const { data, isLoading, callApi } = useAPI(
    editId ? `${API.ROUTE.SCHEDULE}/${editData._id}` : `${API.ROUTE.SCHEDULE}`,
    editId ? API.METHOD.PATCH : API.METHOD.POST,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE
  );

  useEffect(() => {
    if (editData && editId) {
      setName(editData.name);
      setTimezone(editData.timeZone);
      preFillScheduleSlot(editData);
    }
  }, [editId, editData]);

  useEffect(() => {
    if (data) {
      closeModal();
    }
  }, [data]);

  const convertToSeconds = (hour: number, minute: number) => {
    return hour * 3600 + minute * 60;
  };

  const getLabel = (day: number) => {
    switch (day) {
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      case 0:
        return "Sunday";
      default:
        return "";
    }
  };

  const preFillScheduleSlot = (editData: any) => {
    if (editData && editData.schedule && editData.schedule.length) {
      const timeSlots = editData.schedule.map((slot: any) => {
        const newslot: any = {
          selected: slot.schedules && slot.schedules.length > 0 ? true : false,
          label: getLabel(slot.day),
          day: slot.day,
          schedules:
            slot.schedules && slot.schedules.length > 0
              ? [
                  {
                    start: {
                      hour: convertSecToHr(slot.schedules[0].startTime),
                      minute: convertSecToMin(slot.schedules[0].startTime)
                    },
                    end: {
                      hour: convertSecToHr(slot.schedules[0].endTime),
                      minute: convertSecToMin(slot.schedules[0].endTime)
                    }
                  }
                ]
              : [
                  {
                    start: {
                      hour: convertSecToHr(32400),
                      minute: convertSecToMin(64800)
                    },
                    end: {
                      hour: convertSecToHr(32400),
                      minute: convertSecToMin(64800)
                    }
                  }
                ]
        };
        console.log("slot =>>>>>", editData);
        return newslot;
      });
      console.log(timeSlots);
      setTimeSlots(timeSlots);
    }
  };
  const handleSubmit = () => {
    const formatTimeSlot: any = timeSlot.map((time: any) => {
      const updatedSlots = time.schedules.map((slot: any) => {
        const updatedSlot = {
          startTime: convertToSeconds(slot.start.hour, slot.start.minute),
          endTime: convertToSeconds(slot.end.hour, slot.end.minute)
        };
        return updatedSlot;
      });
      return { day: time.day, schedules: time.selected ? updatedSlots : [] };
    });
    const payload = {
      name,
      timeZone: timezone,
      schedule: formatTimeSlot
    };
    callApi(payload);
  };

  const onInputSlotFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.select();
  };

  const onSlotChange = (modifiedSlot: any, day: number, index: number) => {
    const updatedTimeSlot: any[] = timeSlot.map((time) => {
      if (time.day === day) {
        time.schedules[index] = modifiedSlot;
      }
      return time;
    });
    setTimeSlots(updatedTimeSlot);
  };

  return (
    <div id="drawer-timepicker">
      <div className="w-full pb-6 mb-6">
        <Input
          label={"Schedule name"}
          inputType={INPUT_TYPE.TEXT}
          placeholder={t(
            TRANSLATATION_KEY.SCHEDULE.FORM.SCHEDULE_NAME_FIELD.PLACEHOLDER
          )}
          value={name}
          onChange={(value: string) => setName(value)}
          isValid={true}
          validationMessage={t(
            TRANSLATATION_KEY.SCHEDULE.FORM.SCHEDULE_NAME_FIELD.VALIDATION_MSG
          )}
          disabled={isLoading}
        />

        <Select
          label={"Timezone"}
          options={TIMEZONE_OPTIONS}
          value={timezone}
          onChange={(value: string) => setTimezone(value)}
        />
      </div>

      <ul>
        {timeSlot &&
          timeSlot &&
          timeSlot.length > 0 &&
          timeSlot.map((slot: any, index: number) => (
            <li className="flex gap-3 items-center" key={index}>
              <input
                checked={slot.selected}
                onChange={(e: any) => {
                  const updatedTimeSlot: any[] = timeSlot.map((time) => {
                    if (time.day === slot.day && time) {
                      time.selected = e.target.checked;
                    }
                    return time;
                  });
                  setTimeSlots(updatedTimeSlot);
                }}
                id="monday"
                name="days"
                type={INPUT_TYPE.CHECKBOX}
                value="monday"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <span className="flex-1">{slot?.label}</span>
              <div className="schedule-timeslot-container">
                {slot.schedules.length > 0 &&
                  slot.schedules.map((timesl: any, i: number) => (
                    <div
                      key={i}
                      className={
                        slot && (slot.invalidDataError || slot.overlapError)
                          ? "schedule-timeslot active error"
                          : "schedule-timeslot active"
                      }>
                      <input
                        type={INPUT_TYPE.NUMBER}
                        placeholder="hh"
                        value={timesl.start.hour}
                        min="1"
                        max="23"
                        onFocus={onInputSlotFocus}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const modifiedSlot: any = {
                            ...timesl,
                            start: {
                              ...timesl.start,
                              hour: e.target.value
                            }
                          };
                          console.log("timesl", timesl, modifiedSlot);
                          onSlotChange(modifiedSlot, slot.day, i);
                        }}
                      />
                      <small>:</small>
                      <input
                        type={INPUT_TYPE.NUMBER}
                        placeholder=" mm"
                        value={timesl.start.minute}
                        min="0"
                        max="59"
                        onFocus={onInputSlotFocus}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const modifiedSlot: any = {
                            ...timesl,
                            start: {
                              ...timesl.start,
                              minute: e.target.value
                            }
                          };
                          onSlotChange(modifiedSlot, slot.day, i);
                        }}
                      />
                      <p>-</p>
                      <input
                        type={INPUT_TYPE.NUMBER}
                        placeholder="hh"
                        value={timesl.end.hour}
                        min="1"
                        max="23"
                        onFocus={onInputSlotFocus}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const modifiedSlot: any = {
                            ...timesl,
                            end: {
                              ...timesl.end,
                              hour: e.target.value
                            }
                          };
                          onSlotChange(modifiedSlot, slot.day, i);
                        }}
                      />
                      <small>:</small>
                      <input
                        type={INPUT_TYPE.NUMBER}
                        placeholder=" mm"
                        value={timesl.end.minute}
                        min="0"
                        max="59"
                        onFocus={onInputSlotFocus}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const modifiedSlot: any = {
                            ...timesl,
                            end: {
                              ...timesl.end,
                              minute: e.target.value
                            }
                          };
                          onSlotChange(modifiedSlot, slot.day, i);
                        }}
                      />
                      <div
                        className="timeslot-remove-action"
                        onClick={() => {
                          // removeSlot(timeSlot.day, index);
                        }}>
                        -
                      </div>
                    </div>
                  ))}

                <div
                  className="add-schedule"
                  onClick={() => {
                    if (timeSlot.length < 3) {
                      // addSlotHandler(timeSlot.day);
                    } else {
                      // More than 3 slots are not allowed for a single day
                    }
                  }}>
                  +
                </div>
              </div>
            </li>
          ))}
      </ul>

      <div className="-mx-3 flex flex-wrap gap-y-4 justify-end">
        <div className="px-3 py-3">
          <PrimaryButton
            label={t(TRANSLATATION_KEY.SCHEDULE.BUTTON.SAVE)}
            onClick={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default AddScheduleDrawer;
