// Dependencies
import { useEffect, useState } from "react";

// Custom Hook
import { useAPI } from "../../hooks/useApi";

// i18n translation
import { useTranslation } from "react-i18next";

// Components
import Input from "../../components/input/input";
import PrimaryButton from "../../components/button/primary-button";

// Constants
import {
  API,
  DEFAULT_VALUE,
  INPUT_TYPE,
  TRANSLATATION_KEY
} from "../../utils/contants";

// Types
import { InputProps } from "../../common-types/auth-types";

const AddLeadForm = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation();
  const [leadAttributesMapping, setLead] = useState<any>({});
  const [email, setEmail] = useState<InputProps>({
    value: DEFAULT_VALUE.EMPTY,
    isValid: DEFAULT_VALUE.TRUE
  });

  // API Hook
  const { data, isLoading, callApi } = useAPI(
    API.ROUTE.LEADS,
    API.METHOD.POST,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE
  );
  const customFields: any = useAPI(
    API.ROUTE.CUSTOM_FIELDS,
    API.METHOD.GET,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE
  );

  useEffect(() => {
    // fetch all custom fields
    customFields.callApi();
  }, []);

  useEffect(() => {
    // handle API response
    if (data) {
      closeModal();
    }
  }, [data]);

  const onFormSubmit = () => {
    const payload = {
      email: email.value,
      leadAttributesMapping
    };
    callApi(payload);
  };

  return (
    <div className="w-full">
      <div>
        <div className="rounded-md shadow-default dark:bg-boxdark">
          <div className="p-5 px-7.5">
            <h4 className="text-xl font-semibold text-black hover:text-primary dark:text-white dark:hover:text-primary">
              <a>{t(TRANSLATATION_KEY.LEADS.DRAWER.ADD_LEAD_FORM_TITLE)}</a>
            </h4>
          </div>
          <div className="px-7.5">
            <div className="flex flex-wrap items-center justify-between gap-5">
              <form>
                <div className="flex flex-col">
                  <Input
                    label={t(TRANSLATATION_KEY.LEADS.DRAWER.EMAIL_FIELD.LABEL)}
                    inputType={INPUT_TYPE.EMAIL}
                    placeholder={t(
                      TRANSLATATION_KEY.LEADS.DRAWER.EMAIL_FIELD.PLACEHOLDER
                    )}
                    value={email.value}
                    onChange={(value: string) => setEmail({ ...email, value })}
                    isValid={email.isValid}
                    validationMessage={t(
                      TRANSLATATION_KEY.LEADS.DRAWER.EMAIL_FIELD.VALIDATION_MSG
                    )}
                  />
                  {customFields.data &&
                    customFields.data.payload &&
                    customFields.data.payload.length &&
                    customFields.data.payload.map((field: any) => (
                      <Input
                        key={field._id}
                        label={field.label}
                        inputType={INPUT_TYPE.TEXT}
                        placeholder={field.label}
                        value={
                          leadAttributesMapping &&
                          leadAttributesMapping[field._id]
                            ? leadAttributesMapping[field._id]
                            : ""
                        }
                        onChange={(value: string) => {
                          setLead({
                            ...leadAttributesMapping,
                            [field._id]: value
                          });
                        }}
                      />
                    ))}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="-mx-3 flex flex-wrap gap-y-4 justify-end">
        <div className="px-3 py-3">
          <PrimaryButton
            label={t(TRANSLATATION_KEY.LEADS.DRAWER.BUTTON.SAVE)}
            onClick={onFormSubmit}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default AddLeadForm;
