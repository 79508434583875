import axios from "axios";
import iconsSvg from "./iconsvg";

export enum AttachmentUploadStatus {
  Upload = "upload",
  Uploading = "uploading",
  UploadFailed = "upload_failed",
  UploadSuccess = "upload_success",
  Existing = "existing",
  Deleting = "deleting"
}

export type AttachmentType = {
  attachmentId: number;
  attachmentIdentifier?: string;
  status: AttachmentUploadStatus;
  file: any;
  attachmentSize?: number;
};

type GenerateIdArgs = {
  prefix: string;
  variableLength?: number;
};

const EmailContentSizeAllowed = 20 * 1024 * 1000;
const NumberOfAttachmentsAllowed = 4;

export const docExtensions = [
  ".doc",
  ".docx",
  ".odt",
  ".msg",
  ".rtf",
  ".tex",
  ".txt",
  ".wks",
  ".wps",
  ".wpd"
];

export const pdfExtensions = [".pdf"];

export const imageFileExtensions = [
  ".ai",
  ".bmp",
  ".gif",
  ".ico",
  ".jpeg",
  ".jpg",
  ".max",
  ".obj",
  ".png",
  ".ps",
  ".psd",
  ".svg",
  ".tif",
  ".tiff",
  ".3ds",
  ".3dm"
];

export const videoFileExtensions = [
  ".avi",
  ".flv",
  ".h264",
  ".m4v",
  ".mkv",
  ".mov",
  ".mp4",
  ".mpg",
  ".mpeg",
  ".rm",
  ".swf",
  ".vob",
  ".wmv",
  ".3g2",
  ".3gp"
];

export const sheetFileExtensions = [".ods", ".xlr", ".xls", ".xlsx", ".csv"];

export const presentationFileExtensions = [
  ".key",
  ".odp",
  ".pps",
  ".ppt",
  ".pptx"
];

export const zipFileExtensions = [".zip"];

export const validAttachmentExtensions = [
  ...docExtensions,
  ...imageFileExtensions,
  ...videoFileExtensions,
  ...sheetFileExtensions,
  ...presentationFileExtensions,
  ...pdfExtensions,
  ...zipFileExtensions
];

const generateId = ({ prefix, variableLength = 10 }: GenerateIdArgs) =>
  `${prefix}-${generateRandomString(variableLength)}`;

const getStringSize = (content: string) => Buffer.byteLength(content, "utf-8");

const generateRandomString = (length: number): string => {
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = "";

  for (let i = length; i > 0; i--) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }

  return result;
};

const checkTotalEmailContentSizeValidator = ({
  content,
  attachmentsSizeInBytes
}: any): boolean => {
  const contentSizeInBytes = getStringSize(content);

  const totalEmailSize = contentSizeInBytes + attachmentsSizeInBytes;

  return totalEmailSize > EmailContentSizeAllowed;
};

export const generateEditorId = (variableLength?: number) =>
  generateId({ prefix: "editor", variableLength });

enum AlignmentActions {
  Left = "justifyLeft",
  Right = "justifyRight",
  Center = "justifyCenter",
  Justify = "justifyFull"
}

const itemsData = [
  {
    icon: "align-left",
    action: AlignmentActions.Left
  },
  {
    icon: "align-center",
    action: AlignmentActions.Center
  },
  {
    icon: "align-right",
    action: AlignmentActions.Right
  },
  {
    icon: "align-justify",
    action: AlignmentActions.Justify
  }
];

export const addAlignmentSplitButton = (editor: any) => {
  let selectedAlignmentAction = AlignmentActions.Left;
  const alignmentSplitButton = {
    icon: "alignment",
    onAction: () => {
      editor.execCommand(selectedAlignmentAction);
    },
    onItemAction: (api: any, value: any) => {
      editor.execCommand(value.action);
      selectedAlignmentAction = value.action;
    },
    fetch: (callback: any) => {
      const items = itemsData.map((data) => ({
        type: "choiceitem",
        icon: data.icon,
        value: { action: data.action }
      }));
      callback(items);
    }
  };
  editor.ui.registry.addSplitButton("alignment", alignmentSplitButton);
};

export const addIcon = (editor: any) => {
  Object.keys(iconsSvg).forEach((key: any) => {
    editor.ui.registry.addIcon(key, iconsSvg[key]);
  });
};

export const getAcceptedExtensionsForAttachment = (): string =>
  validAttachmentExtensions.join(",");

export const hasValidExtension = (fileName: string): boolean =>
  new RegExp(
    `(${validAttachmentExtensions.join("|").replace(/\./g, "\\.")})$`,
    "i"
  ).test(fileName);

export const checkIsTotalAttachmentSizeIsValid = (
  attachments: AttachmentType[],
  file: any,
  content: string
): boolean => {
  let attachmentsSizeInBytes: number = parseInt(file.size, 10);

  attachments.forEach((item) => {
    attachmentsSizeInBytes += parseInt(item.file.size, 10);
  });

  return !checkTotalEmailContentSizeValidator({
    content,
    attachmentsSizeInBytes
  });
};

export const showToasterError = () => {
  //   toaster.error(msg, { theme: Theme.New });
};

export const addFileToTheLocalAttachments = ({
  file,
  localAttachments,
  onLocalAttachmentChange,
  onLocalAttachmentMetaChange,
  breakLoop,
  content
}: any) => {
  // Validating is attachments hard limit is exceeding `NumberOfAttachmentsAllowed` or not
  if (localAttachments.length < NumberOfAttachmentsAllowed) {
    // Validating is total size of attachments is exceeding `EmailContentSizeAllowed` or not
    if (checkIsTotalAttachmentSizeIsValid(localAttachments, file, content)) {
      // Validating is attachment has a supported file extension or not
      if (hasValidExtension(file.name)) {
        // Generating random attachment identifier for a key to link up the attachment status
        const attachmentIdentifier = generateRandomString(6);

        // Creating a new attachment data object
        const attachment = {
          attachmentIdentifier,
          file,
          status: AttachmentUploadStatus.Upload,
          attachmentSize: file.size
        };

        // Creating a new attachment meta data object
        const attachmentsMeta = {
          percentage: 0,
          attachmentIdentifier,
          source: axios.CancelToken.source()
        };

        // Storing the attachment and its meta in local attachment array
        onLocalAttachmentChange(attachment);
        onLocalAttachmentMetaChange(attachmentsMeta);
      } else {
        // Show error as attachment does not have valid extension
        // showToasterError(t('messages.unsupported_file_type'));
      }
    } else {
      // Show error as attachments total size is exceeding the limit `EmailContentSizeAllowed`
      //   const sizeInMb = Math.round(EmailContentSizeAllowed / 1024 / 1000);
      //   showToasterError(
      //     `${t('messages.email_content_size_exceeds')} ${sizeInMb} MB.`,
      //   );
      breakLoop();
    }
  } else {
    // Show error as attachments count exceeding the limit `NumberOfAttachmentsAllowed`
    // showToasterError(
    //   `${t(
    //     'messages.number_of_attachments_allowed_1',
    //   )} ${NumberOfAttachmentsAllowed} ${t(
    //     'messages.number_of_attachments_allowed_2',
    //   )}`,
    // );
    breakLoop();
  }
};
