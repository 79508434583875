// Dependencies
import { useEffect, useState } from "react";

// i18n translation
import { useTranslation } from "react-i18next";

// Components
import Drawer from "../../components/drawer/drawer";
import AddCustomField from "./add-custom-field";
import EditCustomField from "./edit-custom-field";

// Custom Hook
import { useAPI } from "../../hooks/useApi";

// Constants
import {
  API,
  DEFAULT_VALUE,
  DRAWER_WIDTH,
  TABLE,
  TRANSLATATION_KEY
} from "../../utils/contants";

// Types
import { DrawerWidthType } from "../../common-types/common-type";
import PageHeading from "../../components/page-heading/page-heading";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import TableSkeletonLoading from "../../components/table/table-skeleton-loading";
import NoData from "../../components/no-data/no-data";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const CustomFields = () => {
  const { t } = useTranslation();
  const [customFields, setCustomFields] = useState<any[]>([]);
  const [searchValue] = useState<string>(DEFAULT_VALUE.EMPTY);
  const [page] = useState<number>(1);
  const [showSmtpImapModal, setShowSmtpImapModal] = useState<boolean>(
    DEFAULT_VALUE.FALSE
  );
  const [editData, setEditData] = useState<{
    id: number;
    label: string;
  } | null>(DEFAULT_VALUE.NULL);
  const [showEditModal, setShowEditModal] = useState<boolean>(
    DEFAULT_VALUE.FALSE
  );
  const { data, isLoading, callApi } = useAPI(
    `${API.ROUTE.CUSTOM_FIELD}?page=${page}&pageSize=${TABLE.PAGE_SIZE}&search=${searchValue}`,
    API.METHOD.GET,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE
  );

  useEffect(() => {
    callApi();
  }, [page, searchValue]);

  useEffect(() => {
    if (data) {
      const { payload }: any = data;
      setCustomFields(payload?.customFields);
    }
  }, [data]);

  const toggleModalHandler = () => {
    setShowSmtpImapModal(!showSmtpImapModal);
  };

  const onEdit = (id: number, label: string) => {
    setEditData({ id, label });
    setShowEditModal(true);
  };

  const toggleEditModalHandler = () => {
    setShowEditModal(false);
    callApi();
  };

  // const onSearch = (e: any) => {
  //   setSearchValue(e.target.value);
  // };

  return (
    <>
      <PageHeading
        title="Custom Fields"
        subTitle="Create custom fields to get details from prospects."
        showAddBtn={true}
        btnTitle="Create Custom Field"
        onBtnClick={toggleModalHandler}
      />

      <div>
        <div className="p-4 md:p-8 flow-root">
          <ul role="list" className="-my-5">
            <li className="py-4 px-4 bg-[#f3f4f6] rounded-lg">
              <div className="flex items-center space-x-4">
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-bold text-gray-900">
                    Label
                  </p>
                </div>
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-bold text-gray-900">
                    Slug
                  </p>
                </div>
                <div className="min-w-0">
                  <p className="truncate text-sm font-bold text-gray-900">
                    Action
                  </p>
                </div>
              </div>
            </li>
            {!isLoading &&
              customFields.length > 0 &&
              customFields.map((field: any) => (
                <li
                  key={field._id}
                  className="py-2 px-4 border mt-2 border-[#e2e8f0] rounded-md hover:bg-[#f3f4f6]">
                  <div className="flex items-center space-x-4">
                    <div className="min-w-0 flex-1">
                      <p className="truncate text-sm font-medium text-gray-900">
                        {field.label}
                      </p>
                    </div>
                    <div className="min-w-0 flex-1">{field.slug}</div>
                    <div>
                      <Menu
                        as="div"
                        className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95">
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      onEdit(field._id, field.label);
                                    }}
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm"
                                    )}>
                                    Edit
                                  </Link>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </li>
              ))}

            {!isLoading && customFields.length <= 0 && (
              <div className="w-full bg-[#f3f4f6] mt-2 rounded-lg py-32">
                <NoData buttonName="Custom Field" onAdd={toggleModalHandler} />
              </div>
            )}

            {isLoading && (
              <div className="w-full">
                <TableSkeletonLoading />
              </div>
            )}
          </ul>
        </div>
      </div>

      <Drawer
        title={t(TRANSLATATION_KEY.CUSTOM_FIELD.DRAWER.ADD_TITLE)}
        showModal={showSmtpImapModal}
        width={DRAWER_WIDTH.MIN as DrawerWidthType}
        onCloseModal={toggleModalHandler}>
        <AddCustomField
          closeModal={() => {
            toggleModalHandler();
            callApi();
          }}
        />
      </Drawer>

      <Drawer
        title={TRANSLATATION_KEY.CUSTOM_FIELD.DRAWER.EDIT_TITLE}
        showModal={showEditModal}
        width={DRAWER_WIDTH.MIN as DrawerWidthType}
        onCloseModal={toggleEditModalHandler}>
        <EditCustomField
          editData={editData}
          closeModal={() => {
            setShowEditModal(false);
            callApi();
          }}
        />
      </Drawer>
    </>
  );
};

export default CustomFields;
