// SVG Icon
import { FaEllipsisV } from "react-icons/fa";
import { useAPI } from "../../hooks/useApi";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "../../utils/contants";

const TableAction = ({
  onEdit,
  onView,
  onDelete,
  deleteUrl,
  id,
  permission = {
    can_view: false,
    can_edit: false,
    can_delete: false
  }
}: {
  onEdit: () => void;
  onView: () => void;
  onDelete: () => void;
  deleteUrl?: string;
  id: number;
  permission: any;
}) => {
  const { data, callApi } = useAPI(
    `${deleteUrl}/${id}`,
    API.METHOD.DELETE,
    null,
    true,
    "Deleted successfully!"
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger: any = useRef(null);
  const dropdown: any = useRef(null);

  useEffect(() => {
    if (data) {
      onDelete();
    }
  }, [data]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: { target: EventTarget | null }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current?.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: { keyCode: number }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });
  return (
    <div className="relative flex items-center space-x-3.5">
      <div
        className="hover:text-primary hover:bg-indigo-50 rounded-md cursor-pointer
        transition-colors group p-2"
        ref={trigger}
        onClick={() => setDropdownOpen(!dropdownOpen)}>
        <FaEllipsisV />
      </div>
      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        style={{ left: "0.5rem", top: "1.5rem" }}
        id="slide"
        className={`pointer absolute mt-2 flex w-32 flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark ${
          dropdownOpen === true ? "block" : "hidden"
        }`}>
        <ul className="flex flex-col gap-5 border-b border-stroke px-6 py-4 dark:border-strokedark">
          {permission.can_view && (
            <li>
              <Link
                onClick={onView}
                to="#"
                className="flex font-nunito items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base">
                View
              </Link>
            </li>
          )}
          {permission.can_edit && (
            <li>
              <Link
                onClick={onEdit}
                to="#"
                className="flex font-nunito items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base">
                Edit
              </Link>
            </li>
          )}
          {permission.can_delete && (
            <li>
              <Link
                onClick={() => {
                  callApi();
                }}
                to="#"
                className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base">
                Delete
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TableAction;
