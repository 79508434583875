import { PlusIcon } from "@heroicons/react/20/solid";

type IPageHeading = {
  title: string;
  subTitle: string;
  showAddBtn: boolean;
  btnTitle?: string;
  onBtnClick?: () => void;
};

const PageHeading = ({
  title,
  subTitle,
  showAddBtn,
  btnTitle,
  onBtnClick
}: IPageHeading) => {
  return (
    <div className="lg:flex p-4 md:p-8 lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {title}
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="ml-1 flex items-center text-sm text-gray-500">
            {subTitle || ""}
          </div>
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4">
        <span className="sm:ml-3">
          {showAddBtn && (
            <button
              type="button"
              onClick={() => onBtnClick && onBtnClick()}
              className="inline-flex items-center border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {btnTitle}
            </button>
          )}
        </span>
      </div>
    </div>
  );
};

export default PageHeading;
