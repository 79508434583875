// Dependencies
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Components
import Table from "../../components/table/table";
import PrimaryButton from "../../components/button/primary-button";
import Select from "../../components/select/select";
import AddEmailToSequence from "./add-email-to-sequence";

// i18n Translation
import { useTranslation } from "react-i18next";

// Custom Hook
import { useAPI } from "../../hooks/useApi";

// Constants
import {
  API,
  DEFAULT_VALUE,
  DRAWER_WIDTH,
  SEQUENCE_SETTING_TABLE_FIELDS,
  TABLE,
  TRANSLATATION_KEY
} from "../../utils/contants";

// Types
import { DrawerWidthType } from "../../common-types/common-type";
import Modal from "../../components/modal/modal";

const Setting = ({ sequence }: { sequence: any }) => {
  const { t } = useTranslation();
  const [typeOptions, setTypeOptions] = useState<any[]>([]);
  const [scheduleId, setScheduleId] = useState<string>(DEFAULT_VALUE.EMPTY);
  const [schedules, setSchedule] = useState([]);
  const [page, setPage] = useState<number>(1);
  const [showSmtpImapModal, setShowSmtpImapModal] = useState<boolean>(
    DEFAULT_VALUE.FALSE
  );
  const { id } = useParams();

  const getSchedule: any = useAPI(
    `${API.ROUTE.SCHEDULE}`,
    API.METHOD.GET,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE
  );

  const getScheduleId = (value: string) => {
    const schedule: any = schedules.find(
      (schedule: any) => schedule.name === value
    );
    if (schedule) {
      return schedule._id;
    }
    return DEFAULT_VALUE.NULL;
  };

  const saveSchedule = useAPI(
    `${API.ROUTE.SEQUENCE}/${id}${API.ROUTE.SCHEDULE}/${getScheduleId(scheduleId)}`,
    API.METHOD.PATCH,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE,
    t(TRANSLATATION_KEY.SEQUENCE.FORM.UPDATED_SCHEDULE_API_SUCCESS_MSG)
  );

  useEffect(() => {
    getSchedule.callApi();
  }, []);

  useEffect(() => {
    if (getSchedule.data) {
      setSchedule(getSchedule?.data?.payload?.schedules);
      if (
        getSchedule?.data?.payload?.schedules &&
        getSchedule?.data?.payload?.schedules.length
      ) {
        const names = getSchedule?.data?.payload?.schedules.map(
          ({ name }: any) => name
        );
        setTypeOptions(names);
        setScheduleId(names[0]);
      }
    }
  }, [getSchedule.data]);

  useEffect(() => {
    if (saveSchedule.data) {
      setScheduleId(DEFAULT_VALUE.EMPTY);
    }
  }, [saveSchedule.data]);

  const toggleModalHandler = () => {
    setShowSmtpImapModal(!showSmtpImapModal);
  };

  const changePage = (page: number) => {
    setPage(page);
  };

  const saveScheduleHandler = () => {
    saveSchedule.callApi();
  };

  return (
    <>
      <form className="space-y-6 pt-8" action="#" method="POST">
        <div className="md:grid md:grid-cols-3">
          <div className="md:col-span-1">
            <h3 className="text-sm font-bold leading-6 text-gray-900">
              Email Accounts
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Add multiple email accounts to start sending emails from a
              campaign at a faster rate
            </p>
          </div>
          <div className="md:col-span-2 items-left text-right">
            <a
              href="#"
              className="mt-2 text-sm inline-flex font-medium items-center text-blue-600 hover:underline"
              onClick={toggleModalHandler}>
              + Add more email accounts
            </a>
          </div>
        </div>

        <Table
          meta={DEFAULT_VALUE.NULL}
          page={page}
          pageSize={TABLE.PAGE_SIZE}
          onPageChange={changePage}
          fields={SEQUENCE_SETTING_TABLE_FIELDS}
          dataList={sequence.sequenceEmailAccounts}
          onEdit={() => {}}
          title={t(TRANSLATATION_KEY.SEQUENCE.DRAWER.EMAIL_ACCOUNT_TITLE)}
          onAdd={toggleModalHandler}
          isLoading={false}
          permission={{
            can_view: DEFAULT_VALUE.FALSE,
            can_edit: DEFAULT_VALUE.FALSE,
            can_delete: DEFAULT_VALUE.TRUE
          }}
        />

        <div className="mt-8">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-sm font-bold leading-6 text-gray-900">
                Sending Schedule
              </h3>
              <p className="mt-1 text-xs text-gray-500">
                Choose the schedule for sending emails from this sequence.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 md:col-span-2 md:mt-0">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Select
                label=""
                options={typeOptions}
                value={scheduleId}
                onChange={(value: string) => setScheduleId(value)}
              />

              <div className="w-32 pt-5">
                <PrimaryButton
                  isLoading={getSchedule.isLoading}
                  label={t(TRANSLATATION_KEY.SEQUENCE.BUTTON.SAVE)}
                  onClick={saveScheduleHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal
        title={""}
        showModal={showSmtpImapModal}
        width={DRAWER_WIDTH.MAX as DrawerWidthType}
        onCloseModal={toggleModalHandler}>
        <AddEmailToSequence
          id={id}
          closeModal={() => {
            toggleModalHandler();
            // [TODO]: Reshresh Sequence
          }}
        />
      </Modal>
    </>
  );
};

export default Setting;
