// Dependencies
import { useEffect, useState } from "react";

// Components
import Steps from "./step/steps";
import SequenceProspects from "./sequence-prospects";
import Setting from "./sequence-setting";
import { useAPI } from "../../hooks/useApi";
import { API, DEFAULT_VALUE, ROUTES } from "../../utils/contants";
import { useNavigate, useParams } from "react-router-dom";
import TableStatus from "../../components/table/table-status";
import Report from "./report/report";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const ViewSequence = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const [sequence, setSequence] = useState<any>(null);
  const [step, setStep] = useState<string>(type || "Steps");
  const [input, showInput] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const { data, isLoading, callApi } = useAPI(
    `${API.ROUTE.SEQUENCE}/${id}`,
    API.METHOD.GET,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE
  );

  useEffect(() => {
    setStep(type || "Steps");
  }, [type]);

  useEffect(() => {
    getSequenceHandler();
  }, []);

  const getSequenceHandler = () => {
    !isLoading && callApi();
  };

  useEffect(() => {
    if (data) {
      const sequenceData: any = data;
      setSequence(sequenceData.payload?.sequence[0]);
      setInputValue(sequenceData.payload?.sequence[0].name);
    }
  }, [data]);

  const tabs = [
    { name: "Steps" },
    { name: "Leads" },
    { name: "Settings" },
    { name: "Report" }
  ];

  return (
    <div className="relative p-4 md:p-8">
      <div className="relative pb-5 sm:pb-0">
        <div
          className="md:flex md:items-center md:justify-between w-auto"
          // style={{ position: "absolute", top: "-5.5rem" }}
          onBlur={() => showInput(false)}
          onClick={() => showInput(true)}>
          {input ? (
            <form className="w-full">
              <input
                autoFocus
                autoSave=""
                name="sequence-name"
                placeholder="Campaign name"
                className="w-[30%]"
                value={inputValue}
                onChange={(e: any) => setInputValue(e.target.value)}
              />
            </form>
          ) : (
            <h3 className="text-lg cursor-pointer hover:ring-1 hover:ring-primary font-medium leading-6 text-[#111827]">
              {sequence ? sequence.name : ""}
            </h3>
          )}
        </div>
        <div className="mt-4">
          <div className="">
            <nav className="-mb-px flex border-b-2 border-[#d1d5db]">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href="#"
                  onClick={() =>
                    navigate(ROUTES.STEP + "/" + tab.name + "/" + id)
                  }
                  className={classNames(
                    tab.name === step
                      ? "border-2 bg-[#f1f5f9] border-b-0 border-[#e2e8f0] rounded-tr-lg rounded-tl-lg text-black"
                      : "border-[#d1d5db] text-[#6b7280] hover:text-[#374151] hover:border-[#d1d5db]",
                    "whitespace-nowrap pb-2 pt-3 px-3 font-medium text-sm"
                  )}
                  aria-current={tab.name === step ? "page" : undefined}>
                  {tab.name}
                </a>
              ))}
              <a className="flex items-center ml-auto mt-0">
                <TableStatus
                  id={sequence && sequence._id}
                  activateStatusUrl={`${API.ROUTE.SEQUENCE}/activate-sequence/`}
                  deactivateStatusUrl={`${API.ROUTE.SEQUENCE}/deactivate-sequence/`}
                  value={sequence && sequence.sequenceStatus}
                  refreshData={() => {
                    callApi();
                  }}
                />
              </a>
            </nav>

            <div>
              {step == "Steps" && (
                <div className="leading-relaxed block">
                  <Steps sequence={sequence} />
                </div>
              )}
              {step == "Leads" && <SequenceProspects sequence={sequence} />}
              {step == "Settings" && <Setting sequence={sequence} />}
              {step == "Report" && <Report />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSequence;
