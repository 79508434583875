// Dependencies
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Custom Hook
import { useAPI } from "../../hooks/useApi";

// Constants
import { API, ROUTES } from "../../utils/contants";

const VerifyEmailAccount = () => {
  const navigate = useNavigate();
  const { data, callApi } = useAPI(
    `${API.ROUTE.EMAIL_ACCOUNT}${window.location.pathname == "/verify/gmail" ? "/verify/google" : window.location.pathname}`,
    API.METHOD.POST,
    null,
    true
  );

  useEffect(() => {
    callApi({ signedUrl: window.location.href });
  }, []);

  useEffect(() => {
    if (data) {
      navigate(ROUTES.SETTINGS);
    }
  }, [data]);

  return <div>Loading...</div>;
};

export default VerifyEmailAccount;
