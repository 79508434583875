import { ProviderProps } from "../common-types/common-type";
import { TableField } from "../common-types/table-type";

const ROUTES = {
  SETTINGS: "/settings",
  ONBOARD: "/onboard/",
  LOGIN: "/login",
  REGISTER: "/register",
  PROFILE: "/profile",
  SEQUENCE: "/sequence",
  STEP: "/step"
};

const API = {
  METHOD: {
    POST: "post",
    GET: "get",
    PATCH: "patch",
    DELETE: "delete"
  },
  ROUTE: {
    LOGIN: "/user/login",
    ONBOARD: "/user/onboard",
    REGISTER: "/user/register",
    CONNECT_SOCIAL_ACCOUNT: "/email-account/connect/",
    CONNECT_SMTP_ACCOUNT: "/email-account/connect-smtp-imap",
    EMAIL_ACCOUNT: "/email-account",
    LEADS: "/lead",
    LEAD_IMPORT: "/lead/import",
    CUSTOM_FIELDS: "/custom-field/all",
    CUSTOM_FIELD: "/custom-field",
    SEQUENCE: "/sequence",
    SCHEDULE: "/schedule",
    ADD_STEP: "/sequence/sequence-step",
    NOTIFICATIONS: "/notification",
    DISCONNECT_EMAIL: "/email-account/disconnect"
  }
};

const DEFAULT_VALUE = {
  EMPTY: "",
  NULL: null,
  FALSE: false,
  TRUE: true
};

const DRAWER_WIDTH = {
  MIN: "Min",
  MAX: "Max"
};

const LOCAL_STORAGE = {
  TOKEN: "token"
};

const INPUT_TYPE = {
  EMAIL: "email",
  TEXT: "text",
  PASSWORD: "password",
  NUMBER: "number",
  FILE: "file",
  CHECKBOX: "checkbox"
};

const EVENT_LISTENERS: any = {
  CLICK: "click",
  KEY_DOWN: "keydown"
};

const ADD_EMAIL_ACCOUNT_BY = {
  GOOGLE: {
    TITLE: "Google",
    SUB_TITLE: "Gmail & Google Workspace"
  },
  MICROSOFT: {
    TITLE: "Microsoft",
    SUB_TITLE: "Exchange, O365, Outlook & Hotmail"
  },
  SMTP: {
    TITLE: "SMTP/IMAP",
    SUB_TITLE: "Any other Email Service provider account"
  }
};

const NOTIFICATION_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning"
};

const ACCOUNT_TYPE = {
  GOOGLE: "google",
  MICROSOFT: "microsoft"
};

const TABS = {
  EMAIL_ACCOUNTS: {
    GENERAL: "general",
    SENDING: "sending"
  }
};

const FILE_TYPE = {
  CSV: "text/csv"
};

const IMPORT_LEAD_FILE_TYPE = ".csv";

const TABLE = {
  PAGE_SIZE: 15
};

const ONABOARD_PAGE = {
  INPUT_FIELD_KEY: {
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    EMAIL: "email",
    PHONE_NUMBER: "phoneNumber",
    PASSWORD: "password"
  }
};

const TRANSLATATION_KEY: any = {
  APP: {
    NAME: "app.name"
  },
  LOGIN: {
    FORM_TITLE: "login.form.title",
    EMAIL_FIELD: {
      LABEL: "login.form.emailField",
      PLACEHOLDER: "login.form.emailPlaceholder",
      VALIDATION_MSG: "login.form.emailValidationMessage"
    },
    PASSWORD_FIELD: {
      LABEL: "login.form.passwordField",
      PLACEHOLDER: "login.form.passwordPlaceholder",
      VALIDATION_MSG: "login.form.passwordValidationMessage"
    },
    BUTTON: {
      SIGN_IN: "login.form.signInBtn",
      SIGN_UP: "login.form.signUpLink"
    },
    DONT_HAVE_ACCOUNT_LABEL: "login.form.dontHaveAccount",
    API_SUCCESS_MSG: "login.form.message"
  },
  ONBOARD: {
    API_SUCCESS_MSG: "onboard.message.successMessage",
    FORM: {
      TITLE: "onboard.form.title",
      firstName: {
        LABEL: "onboard.form.firstNameField",
        PLACEHOLDER: "onboard.form.firstNamePlaceholder",
        VALIDATION_MSG: "onboard.form.firstNameValidationMessage"
      },
      lastName: {
        LABEL: "onboard.form.lastNameField",
        PLACEHOLDER: "onboard.form.lastNamePlaceholder",
        VALIDATION_MSG: "onboard.form.lastNameValidationMessage"
      },
      email: {
        LABEL: "onboard.form.emailField",
        PLACEHOLDER: "onboard.form.emailPlaceholder",
        VALIDATION_MSG: "onboard.form.emailValidationMessage"
      },
      phoneNumber: {
        LABEL: "onboard.form.phoneNumberField",
        PLACEHOLDER: "onboard.form.phoneNumberPlaceholder",
        VALIDATION_MSG: "onboard.form.phoneNumberValidationMessage"
      },
      password: {
        LABEL: "onboard.form.passwordField",
        PLACEHOLDER: "onboard.form.passwordPlaceholder",
        VALIDATION_MSG: "onboard.form.passwordValidationMessage"
      }
    },
    BUTTON: "onboard.form.onboardBtn"
  },
  REGISTER: {
    API_SUCCESS_MSG: "register.message.successMessage",
    FORM: {
      TITLE: "register.form.title",
      EMAIL_FIELD: {
        LABEL: "register.form.emailField",
        PLACEHOLDER: "register.form.emailPlaceholder",
        VALIDATION_MSG: "register.form.emailValidationMessage"
      }
    },
    BUTTON: {
      SIGN_UP: "register.form.signUpBtn",
      SIGN_IN: "register.form.signInLink"
    },
    ACCOUNT_EXIST_TEXT: "register.form.alreadyAccountExist"
  },
  CUSTOM_FIELD: {
    TITLE: "custom-field.title",
    FORM: {
      LABEL_FIELD: {
        LABEL: "custom-field.form.labelField",
        PLACEHOLDER: "custom-field.form.labelPlaceholder",
        VALIDATION_MSG: "custom-field.form.labelValidationMsg"
      },
      TYPE_FIELD: {
        LABEL: "custom-field.form.typeField",
        VALIDATION_MSG: "custom-field.form.typeValidationMsg"
      }
    },
    BUTTON: {
      SUBMIT: "custom-field.form.submitBtn"
    },
    DRAWER: {
      ADD_TITLE: "custom-field.drawer.title",
      EDIT_TITLE: "custom-field.drawer.edit-title"
    }
  },
  LEADS: {
    TITLE: "leads.title",
    DRAWER: {
      TITLE: "leads.drawer.title",
      IMPORT_LEAD_TITLE: "leads.drawer.importLeadTitle",
      ADD_LEAD_FORM_TITLE: "leads.form.add-form-title",
      CLICK_TO_UPLOAD_LINK: "leads.form.clickToUploadLink",
      DRAG_AND_DROP: "leads.form.dragAndDrop",
      CSV: "leads.form.csv",
      MAX_FILE_LIMIT: "leads.form.maxFileLimit",
      MAP_YOUR_FIELD: "leads.form.mapYourField",
      OPTION: "leads.form.option",
      EMAIL_FIELD: {
        LABEL: "leads.form.emailField",
        PLACEHOLDER: "leads.form.emailFieldPlaceholder",
        VALIDATION_MSG: "leads.form.emailValidationMessage"
      },
      API_SUCCESS_MSG: "leads.form.importApiSuccessMsg",
      BUTTON: {
        SAVE: "leads.form.save-btn",
        CANCEL: "leads.button.cancel-btn",
        VERIFY_SAVE: "leads.button.verify-save-btn",
        NEXT: "leads.button.next-btn"
      }
    }
  },
  SCHEDULE: {
    TITLE: "schedule.title",
    ADD_TITLE: "schedule.add-title",
    FORM: {
      API_DELETE_SUCCESS_MSG: "schedule.form.delete-success-msg",
      SCHEDULE_NAME_FIELD: {
        LABEL: "schedule.form.schedule-name-field",
        PLACEHOLDER: "schedule.form.schedule-name-placeholder",
        VALIDATION_MSG: "schedule.form.schedule-name-validation-msg"
      },
      SELECT_TIMEZONE_FIELD: {
        LABEL: "schedule.form.select-timezone-field"
      }
    },
    BUTTON: {
      SAVE: "schedule.button.save-btn"
    }
  },
  SEQUENCE: {
    TITLE: "sequence.title",
    LEADS_TABLE_TITLE: "sequence.leads-table-title",
    FORM: {
      API_SUCCESS_MSG: "sequence.form.api-success-msg",
      API_DELETE_SUCCESS_MSG: "sequence.form.api-delete-success-msg",
      UPDATED_SCHEDULE_API_SUCCESS_MSG:
        "sequence.form.update-schedule-api-success-msg",
      UPDATE_SEQUENCE_API_SUCCESS_MSG:
        "sequence.form.update-sequence-api-success-msg",
      LABEL_FIELD: {
        LABEL: "sequence.form.label-field",
        PLACEHOLDER: "sequence.form.label-field-placeholder",
        VALIDATION_MSG: "sequence.form.label-field-validation-msg"
      },
      EDIT_SEQUENCE: "sequence.form.edit-sequence",
      SCHEDULE_FIELD: "sequence.form.schedule-field"
    },
    DRAWER: {
      ADD_PROSPECTS_TITLE: "sequence.drawer.add-prospects-title",
      EMAIL_ACCOUNT_TITLE: "sequence.drawer.email-account-title"
    },
    BUTTON: {
      SAVE: "sequence.button.save-btn"
    }
  }
};

const CUSTOM_TABLE_FIELD: TableField[] = [
  {
    key: "label",
    label: "Label",
    type: "string"
  },
  {
    key: "slug",
    label: "Slug",
    type: "string"
  }
];

const LEADS_TABLE_FIELD: TableField[] = [
  {
    key: "email",
    label: "Email",
    type: "string"
  },
  {
    key: "verificationStatus",
    label: "Verifiaction Status",
    type: "string"
  }
];

const SCHEDULE_TABLE_FIELDS: TableField[] = [
  {
    key: "name",
    label: "Name",
    type: "string"
  },
  {
    key: "timeZone",
    label: "Time Zone",
    type: "string"
  }
];

const SEQUENCE_TABLE_FIELD: TableField[] = [
  {
    key: "sequenceStatus",
    label: "Status",
    type: "radio"
  },
  {
    key: "name",
    label: "Name",
    type: "string"
  }
];

const SEQUENCE_SETTING_TABLE_FIELDS: TableField[] = [
  {
    key: "email",
    label: "Email",
    type: "string"
  }
];

const SEQUENCE_PROSPECTS_TABLE_FIELDS: TableField[] = [
  {
    key: "leadEmail",
    label: "Email",
    type: "string"
  },
  {
    key: "processedAt",
    label: "Current Step",
    type: "boolean"
  }
];

const IMPORT_LEAD_OPTION: ProviderProps[] = [
  {
    name: "skip"
  },
  {
    name: "override"
  },
  {
    name: "update"
  }
];

const TIMEZONE_OPTIONS: string[] = [
  "America/Los_Angeles",
  "America/New_York",
  "Australia/Sydney",
  "Canada/Mountain",
  "Canada/Central",
  "Canada/Eastern",
  "Europe/Berlin",
  "Europe/London",
  "Europe/Paris",
  "Asia/Tokyo",
  "Asia/Dubai",
  "Asia/Singapore"
];

const MULTIPART_HEADER = {
  "Content-Type": "multipart/form-data"
};

export {
  ROUTES,
  API,
  DEFAULT_VALUE,
  DRAWER_WIDTH,
  LOCAL_STORAGE,
  INPUT_TYPE,
  IMPORT_LEAD_FILE_TYPE,
  EVENT_LISTENERS,
  ADD_EMAIL_ACCOUNT_BY,
  NOTIFICATION_TYPE,
  ACCOUNT_TYPE,
  TABS,
  FILE_TYPE,
  TABLE,
  TRANSLATATION_KEY,
  ONABOARD_PAGE,
  CUSTOM_TABLE_FIELD,
  LEADS_TABLE_FIELD,
  SCHEDULE_TABLE_FIELDS,
  SEQUENCE_TABLE_FIELD,
  SEQUENCE_SETTING_TABLE_FIELDS,
  SEQUENCE_PROSPECTS_TABLE_FIELDS,
  IMPORT_LEAD_OPTION,
  TIMEZONE_OPTIONS,
  MULTIPART_HEADER
};
