// Dependencies
import { useEffect, useState } from "react";

// Custom Hook
import { useAPI } from "../../hooks/useApi";

// Constant
import { API, DEFAULT_VALUE, INPUT_TYPE, TABLE } from "../../utils/contants";

// Components
import PrimaryButton from "../../components/button/primary-button";
import TableFooter from "../../components/table/table-footer";
import Select from "../../components/select/select";

type FieldProps = {
  key: string | null;
  label: string;
};

type MetaProps = {
  page: number;
  pageSize: number;
  totalCount: number;
};

const tableField = [
  {
    key: "name",
    label: "Name",
    type: "string"
  },
  {
    key: "email",
    label: "Email",
    type: "string"
  }
];

const AddProspectsToSequence = ({
  closeModal,
  id,
  sequence
}: {
  closeModal: () => void;
  id: string | undefined;
  sequence: any;
}) => {
  const [leadsArray, setLeads] = useState<any[]>([]);
  const [typeOptions, setTypeOptions] = useState<any[]>([]);
  const [steps] = useState(sequence.steps);
  const [stepId, setStepId] = useState<any>(DEFAULT_VALUE.NULL);
  const [meta, setMeta] = useState<MetaProps | null>(DEFAULT_VALUE.NULL);
  const [page, setPage] = useState<number>(1);
  const [selectedProspects, addProspectToArray] = useState<any>([]);

  // API Hook
  const leads = useAPI(
    `${API.ROUTE.LEADS}?page=${page}&pageSize=${TABLE.PAGE_SIZE}`,
    API.METHOD.GET,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE
  );

  useEffect(() => {
    leads.callApi();
  }, [page]);

  useEffect(() => {
    if (leads.data) {
      const { payload }: any = leads.data;
      setLeads(payload?.leads);
      setMeta(payload?.meta);
    }
  }, [leads.data]);

  useEffect(() => {
    if (sequence) {
      const stepDays = sequence.steps.map(({ stepDay }: any) => stepDay);
      setTypeOptions(stepDays);
      setStepId(stepDays[0]);
    }
  }, [sequence]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const getStepId = (value: string) => {
    const step: any = steps.find((step: any) => step.stepDay === value);
    if (step) {
      return step._id;
    }
    return null;
  };

  const { data, isLoading, callApi } = useAPI(
    `${API.ROUTE.SEQUENCE}/add-leads-to-sequence/${id}/${getStepId(stepId)}`,
    API.METHOD.POST,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE,
    "Prospects added successfully."
  );

  useEffect(() => {
    // handle API response
    if (data) {
      closeModal();
    }
  }, [data]);

  const onFormSubmit = () => {
    const payload = {
      leadIds: selectedProspects
    };
    callApi(payload);
  };

  const selectProspects = (e: any, id: any) => {
    if (e.target.checked) {
      addProspectToArray([...selectedProspects, id]);
    }
  };

  return (
    <div className="w-full">
      <div className="rounded-md dark:bg-boxdark">
        <div className="">
          <section className="data-table-common data-table-two rounded-sm border border-stroke bg-white py-4 shadow-default dark:border-strokedark  dark:bg-boxdark">
            <div className="flex justify-between border-b border-stroke px-8 pb-4 dark:border-strokedark">
              <div className="w-100">
                <input
                  type={INPUT_TYPE.TEXT}
                  className="w-full rounded-md border border-stroke px-5 py-2.5 outline-none focus:border-primary dark:border-strokedark dark:bg-meta-4 dark:focus:border-primary"
                  placeholder="Search..."
                  value=""
                />
              </div>
            </div>
            <div className="rounded-sm bg-white dark:bg-boxdark">
              <div className="grid grid-cols-6 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5">
                <div className="col-span-1 flex items-center">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type={INPUT_TYPE.CHECKBOX}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </div>

                {tableField.length &&
                  tableField.map((field: FieldProps, index: number) => (
                    <div key={index} className="col-span-2 flex items-center">
                      <p className="font-medium">{field.label}</p>
                    </div>
                  ))}
              </div>

              {leadsArray.length > 0 &&
                leadsArray.map((account: any, index: number) => (
                  <div
                    key={index}
                    className="grid grid-cols-6 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5">
                    <div className="col-span-1 flex items-center">
                      <div className="flex items-center">
                        <input
                          id={"checkbox-lead-" + account._id}
                          type={INPUT_TYPE.CHECKBOX}
                          onChange={(e: any) => selectProspects(e, account._id)}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor={"checkbox-lead-" + account._id}
                          className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </div>
                    {tableField.length &&
                      tableField.map((field, i) => (
                        <div key={i} className="col-span-2 flex items-center">
                          <p className="text-sm text-black dark:text-white">
                            {account[field.key]}
                          </p>
                        </div>
                      ))}
                  </div>
                ))}
            </div>
            <TableFooter meta={meta} onPageChange={onPageChange} />

            <Select
              label="Step"
              options={typeOptions}
              value={stepId}
              onChange={(value: string) => setStepId(value)}
            />
          </section>
          <div className="-mx-3 flex flex-wrap gap-y-4 justify-end mt-3">
            <div className="px-3 py-3">
              <PrimaryButton
                label={"Save"}
                onClick={onFormSubmit}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProspectsToSequence;
