// Dependencies
import { useEffect, useState } from "react";

// Components
import Modal from "../../components/modal/modal";
import PageHeading from "../../components/page-heading/page-heading";
import AddEmailAccountOptions from "./add-email-account-options";
import AddEmailBySmtp from "./add-email-by-smtp-form";

// Custom Hook
import { useAPI } from "../../hooks/useApi";

// Constants
import { API, TABLE } from "../../utils/contants";
import TableSkeletonLoading from "../../components/table/table-skeleton-loading";

type EmailAccountSettingsProps = {
  code: string;
  createdAt: string;
  deletedAt: string | null;
  emailAccountId: number;
  id: number;
  updatedAt: string;
  value: string;
};

type EmailAccountProps = {
  createdAt: string;
  deletedAt: string | null;
  email: string;
  emailAccountSettings: EmailAccountSettingsProps[];
  emailConnectionType: string;
  emailServiceProvider: string;
  id: number;
  lastConnectedAt: string;
  name: string;
  pcAccountId: number;
  status: string;
  updatedAt: string;
  userId: number;
};

const Settings = () => {
  const [emailAccounts, setEmailAccounts] = useState<EmailAccountProps[]>([]);
  const [page] = useState<number>(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSmtpImapModal, setShowSmtpImapModal] = useState<boolean>(false);
  const { data, isLoading, callApi } = useAPI(
    `${API.ROUTE.EMAIL_ACCOUNT}?page=${page}&pageSize=${TABLE.PAGE_SIZE}`,
    API.METHOD.GET,
    null,
    true
  );

  useEffect(() => {
    fetchLeads();
  }, []);

  useEffect(() => {
    if (data) {
      const { payload }: any = data;
      setEmailAccounts(payload?.emailAccounts);
    }
  }, [data]);

  const fetchLeads = () => {
    callApi();
  };

  const toggleModalHandler = (openSmtpImapModal: boolean = false) => {
    setShowModal(!showModal);
    if (openSmtpImapModal) setShowSmtpImapModal(!showSmtpImapModal);
    callApi();
  };

  return (
    <>
      <PageHeading
        title="Email Accounts"
        subTitle="Connect your email accounts from where you want to send emails."
        showAddBtn={true}
        btnTitle="Connect Email Account"
        onBtnClick={toggleModalHandler}
      />

      <div>
        <div className="p-4 md:p-8 flow-root">
          <ul role="list" className="-my-5">
            <li className="py-4 px-4 bg-[#f3f4f6] rounded-lg">
              <div className="flex items-center space-x-4">
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-bold text-gray-900">
                    Name
                  </p>
                </div>
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-bold text-gray-900">
                    Email
                  </p>
                </div>
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-bold text-gray-900">
                    Connection Type
                  </p>
                </div>
              </div>
            </li>
            {!isLoading &&
              emailAccounts.length > 0 &&
              emailAccounts.map((emailAcc: any) => (
                <li
                  key={emailAcc._id}
                  className="py-2 px-4 border mt-2 border-[#e2e8f0] rounded-md hover:bg-[#f3f4f6]">
                  <div className="flex items-center space-x-4">
                    <div className="min-w-0 flex-1">
                      <p className="truncate text-sm font-medium text-gray-900">
                        {emailAcc.name}
                      </p>
                    </div>
                    <div className="min-w-0 flex-1">
                      <p className="truncate text-sm font-medium text-gray-900">
                        {emailAcc.email}
                      </p>
                    </div>
                    <div className="min-w-0 flex-1">
                      <p className="truncate text-sm font-medium text-gray-900">
                        {emailAcc.emailConnectionType}
                      </p>
                    </div>
                  </div>
                </li>
              ))}

            {isLoading && (
              <div className="w-full">
                <TableSkeletonLoading />
              </div>
            )}
          </ul>
        </div>
      </div>

      <Modal
        title="Select Account Type"
        showModal={showModal}
        onCloseModal={() => toggleModalHandler(false)}>
        <AddEmailAccountOptions
          closeModal={(value: boolean) => toggleModalHandler(value)}
        />
      </Modal>

      <Modal
        title="Connect SMTP/IMAP Details"
        showModal={showSmtpImapModal}
        width="Max"
        onCloseModal={toggleModalHandler}>
        <AddEmailBySmtp
          closeModal={() => {
            setShowModal(false);
            setShowSmtpImapModal(false);
            callApi();
          }}
        />
      </Modal>
    </>
  );
};

export default Settings;
