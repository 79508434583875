// Dependencies
import { useState } from "react";

// Custom Hook
import { useAPI } from "../../../hooks/useApi";

// i18n translation
import { useTranslation } from "react-i18next";

// Components
import Input from "../../../components/input/input";
import PrimaryButton from "../../../components/button/primary-button";

// Constants
import { API, INPUT_TYPE } from "../../../utils/contants";

const GeneralSetting = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const { isLoading } = useAPI("/user/login", API.METHOD.POST, null, true);

  const onFormSubmit = () => {};

  return (
    <div className="w-full">
      <div className="overflow-scroll">
        <div className="rounded-md shadow-default dark:bg-boxdark">
          <div className="">
            <div className="flex flex-wrap items-center justify-between gap-5">
              <form>
                <Input
                  label={t("smtp.form.fromName")}
                  inputType={INPUT_TYPE.TEXT}
                  placeholder={t("login.form.emailPlaceholder")}
                  value={email}
                  onChange={(value: string) => setEmail(value)}
                  isValid={true}
                  validationMessage={t("login.form.emailValidationMessage")}
                />

                <Input
                  label={t("smtp.form.fromName")}
                  inputType={INPUT_TYPE.TEXT}
                  placeholder={t("login.form.emailPlaceholder")}
                  value={email}
                  onChange={(value: string) => setEmail(value)}
                  isValid={true}
                  validationMessage={t("login.form.emailValidationMessage")}
                />
                <div className="-mx-3 flex flex-wrap gap-y-4">
                  <div className="px-3 py-3">
                    <PrimaryButton
                      label={t("smtpImap.action.connetBtn")}
                      onClick={onFormSubmit}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralSetting;
