import { ReactNode } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  TransitionChild
} from "@headlessui/react";
// SVG Icon
import ModalCloseIcon from "../../assets/svg/modal-close-icon";

type ModalProps = {
  title: string;
  showModal: boolean;
  onCloseModal: () => void;
  children: ReactNode;
  width?: "Max" | "Min";
};

const Drawer = ({
  title,
  showModal,
  onCloseModal,
  width = "Min",
  children
}: ModalProps) => {
  return (
    <Dialog
      className={"relative z-10 " + width}
      open={showModal}
      onClose={onCloseModal}>
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto relative w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700">
              <TransitionChild>
                <div
                  style={{ top: "0.9rem", paddingRight: "1.6rem" }}
                  className="absolute right-0 ml-8 flex pt-4 duration-500 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                  <button
                    type="button"
                    className="relative rounded-md text-gray-300 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => onCloseModal()}>
                    <span className="absolute -inset-2.5" />
                    <span className="sr-only">Close panel</span>
                    <ModalCloseIcon />
                  </button>
                </div>
              </TransitionChild>
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                    {title}
                  </DialogTitle>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  {children}
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default Drawer;
