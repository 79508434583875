// Components
import { Bars3Icon } from "@heroicons/react/24/outline";
import Logo from "../logo/logo";
import DropdownNotification from "./DropdownNotification";
import DropdownUser from "./DropdownUser";
import MobileMenu from "./MobileMenu";

const AdminHeader = ({
  setMobileMenuOpen,
  mobileMenuOpen
}: {
  setMobileMenuOpen: (value: boolean) => void;
  mobileMenuOpen: boolean;
}) => {
  return (
    <header className="relative flex h-14 flex-shrink-0 items-center bg-[#f3f4f6]">
      <Logo />

      <div className="absolute inset-y-0 right-0 flex items-center pr-4 sm:pr-6 md:hidden">
        <button
          type="button"
          className="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
          onClick={() => setMobileMenuOpen(true)}>
          <span className="sr-only">Open main menu</span>
          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <div className="hidden md:flex md:min-w-0 md:flex-1 md:items-center md:justify-between">
        <div className="min-w-0 flex-1"></div>
        <div className="ml-10 flex flex-shrink-0 items-center space-x-10 pr-4">
          <nav aria-label="Global" className="flex space-x-10">
            {/* <a href="#" className="text-sm font-medium text-gray-900">
              Inboxes
            </a>
            <a href="#" className="text-sm font-medium text-gray-900">
              Reporting
            </a>
            <a href="#" className="text-sm font-medium text-gray-900">
              Settings
            </a> */}
          </nav>
          <div className="flex items-center space-x-8">
            <DropdownNotification />
            <DropdownUser />
          </div>
        </div>
      </div>

      <MobileMenu
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />
    </header>
  );
};

export default AdminHeader;
