import { useState } from "react";
import axios, { AxiosResponse } from "axios";
import { useAppDispatch } from "../store/store";
import { API, NOTIFICATION_TYPE } from "../utils/contants";
import { showNotification } from "./notification-handler";

export const useAPI = <T>(
  url: string,
  method: string,
  customHeaders?: any,
  showNotificationOnResponse?: boolean,
  successMsg?: string
) => {
  // const baseURL = "http://localhost:6868/";
  const baseURL = "https://dev-api.polarconnect.io/";
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const callApi = async (data?: any) => {
    setIsLoading(true);
    setError(null);
    try {
      const authToken = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${authToken}`,
        ...customHeaders
      };
      const response: AxiosResponse<T> = await axios({
        headers,
        method,
        url,
        data,
        baseURL
      });
      setError(null);
      setData(response.data);
      showNotificationOnResponse &&
        method !== API.METHOD.GET &&
        showNotification(
          successMsg ? successMsg : "Success message goes here.",
          "Success",
          NOTIFICATION_TYPE.SUCCESS,
          dispatch
        );
      return setIsLoading(false);
    } catch (error: any) {
      let errorMsg = "";
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        window.location.href = window.location.origin + "/login";
      }
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMsg = error.response.data.message;
      } else if (
        error.response &&
        error.response.data.messages &&
        error.response.data.messages.length
      ) {
        errorMsg = error.response.data.messages.join(",");
      } else {
        errorMsg = "Something went wrong, please try again!";
      }
      showNotificationOnResponse &&
        method !== API.METHOD.GET &&
        showNotification(
          errorMsg,
          "Sorry! There was a problem with your request",
          NOTIFICATION_TYPE.ERROR,
          dispatch
        );
      setError(errorMsg);
      setIsLoading(false);
      return errorMsg;
    }
  };

  return { data, isLoading, error, callApi };
};
