// Dependencies
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

// Components
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import NoData from "../../../components/no-data/no-data";

// Custom Hook
import { useAPI } from "../../../hooks/useApi";

// Constants
import { API } from "../../../utils/contants";

const Steps = ({ sequence }: { sequence: any }) => {
  const navigate = useNavigate();
  const [steps, setSteps] = useState<any[]>([]);
  const [dalateVariantId, setDeleteVariantId] = useState<any>(null);
  const [stepId, setStepId] = useState<any>(null);
  const { id } = useParams();

  const deleteVariant = useAPI(
    `sequence/variant/${id}/${stepId}/${dalateVariantId}`,
    API.METHOD.DELETE,
    null,
    true,
    "Variant deleted successfully."
  );

  useEffect(() => {
    if (stepId && dalateVariantId) {
      deleteVariant.callApi();
    }
  }, [stepId, dalateVariantId]);

  useEffect(() => {
    if (sequence) {
      setSteps(sequence.steps);
    }
  }, [sequence]);

  const toggleModalHandler = () => {
    navigate("/step/add/" + id + "/null");
  };

  const toggleVariantHandler = (stepId: string) => {
    navigate("/step/variant/" + id + "/" + stepId);
  };

  const add3Dots = (string: string) => {
    const dots = "...";
    if (string.length > 100) {
      string = string.substring(0, 100) + dots;
    }

    return string;
  };

  const getDate = (sendingDate: string) => {
    return moment().add(sendingDate, "day").format("ddd, d MMM yyyy");
  };

  const deleteVariantHandler = (id: string, stepId: string) => {
    setDeleteVariantId(id);
    setStepId(stepId);
  };

  return (
    <>
      <Breadcrumb
        pageName="Step"
        showAddBtn={true}
        onBtnClick={toggleModalHandler}
      />

      <div className="flex flex-col gap-10">
        <ol className="relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
          {steps.length > 0 &&
            steps.map((step: any, index: number) => (
              <>
                <li key={index} className="mb-10 ms-6">
                  <span className="absolute flex items-center justify-center w-auto p-2 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                    Day {step.stepDay}
                  </span>
                  <span className="text-sm ml-8">
                    Sending as a new email on <b>{getDate(step.stepDay)}</b>,{" "}
                    {step.stepDay === 1
                      ? "if activated today."
                      : "if step 1 is sent on " +
                        moment().format("ddd, d MMM yyyy")}
                  </span>
                  <div className="pt-6">
                    {step.variants.length > 0 &&
                      step.variants.map((variant: any, index: number) => (
                        <div
                          key={index}
                          className="w-full p-3 mt-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                          <a href="#">
                            <h6 className="mb-2 text-md font-semibold tracking-tight text-gray-900 dark:text-white">
                              {variant.subject}
                            </h6>
                          </a>
                          <p className="mb-3 text-sm font-normal text-gray-500 dark:text-gray-400">
                            {add3Dots(variant.content)}
                          </p>
                          <a
                            href="#"
                            className="text-sm inline-flex font-medium items-center text-blue-600 hover:underline"
                            onClick={() =>
                              deleteVariantHandler(variant._id, step._id)
                            }>
                            Delete
                            <svg
                              className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 18 18">
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"
                              />
                            </svg>
                          </a>
                        </div>
                      ))}
                  </div>
                  <a
                    href="#"
                    className="mt-2 text-sm inline-flex font-medium items-center text-blue-600 hover:underline"
                    onClick={() => {
                      toggleVariantHandler(step._id);
                      setDeleteVariantId(null);
                    }}>
                    + Add Variant
                  </a>
                </li>
              </>
            ))}

          {!steps ||
            (steps && steps.length <= 0 && (
              <NoData buttonName={"Step"} onAdd={toggleModalHandler} />
            ))}
        </ol>
      </div>
    </>
  );
};

export default Steps;
