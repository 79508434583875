import { NotificationReducerTypes } from "../components/notification/notification.reducer";
import { AppDispatch } from "../store/store";

export const showNotification = (
  title: string,
  subTitle: string,
  type: string,
  dispatch: AppDispatch
) => {
  return dispatch({
    type: NotificationReducerTypes.ADD,
    payload: {
      id: Math.random(),
      title,
      subTitle: subTitle,
      type: type
    }
  });
};
