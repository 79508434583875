// Dependencies
import { useEffect, useRef, useState } from "react";

// Custom Hook
import { useAPI } from "../../../hooks/useApi";

// Constant
import { API, INPUT_TYPE } from "../../../utils/contants";

// Components
import Input from "../../../components/input/input";
import PrimaryButton from "../../../components/button/primary-button";
import SlateJS from "../../../components/slatejs/slate";

import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useParams, useNavigate } from "react-router-dom";

const steps = [
  {
    id: 0,
    name: "Subject & Header",
    description: "Add your subject and header."
  },
  {
    id: 1,
    name: "Select Template Method",
    description:
      "Select any template method to create efficient and effcetive Email templates."
  },
  {
    id: 2,
    name: "Create Email Template",
    description: "Create your email template."
  }
];

const mailingLists = [
  {
    id: 1,
    title: "Drag & Drop",
    description:
      "Using this method you can create your email template most efficiently and creative.",
    users: "621 users"
  },
  {
    id: 2,
    title: "Manually",
    description: "Using this method you can create your own custom template.",
    users: "1200 users"
  }
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type InputProps = {
  value: string;
  isValid: boolean;
};

const AddStep = () => {
  const { id, stepId } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState<string>("");
  const [formStep, setFormStep] = useState(0);
  const [selectedMailingLists, setSelectedMailingLists] = useState(
    mailingLists[0]
  );
  const [subject, setSubject] = useState<InputProps>({
    value: "",
    isValid: true
  });
  const [preHeader, setPreHeader] = useState<InputProps>({
    value: "",
    isValid: true
  });
  const [stepDay, setStepDay] = useState<InputProps>({
    value: "",
    isValid: true
  });

  const emailEditorRef = useRef<EditorRef>(null);

  const onReady: EmailEditorProps["onReady"] = () => {
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
    // const templateJson = { DESIGN JSON GOES HERE };
    // unlayer.loadDesign(templateJson);
  };

  // API Hook
  const { data, isLoading, callApi } = useAPI(
    stepId && stepId != "null"
      ? `sequence/variant/${id}/${stepId}`
      : `${API.ROUTE.ADD_STEP}/${id}`,
    API.METHOD.POST,
    null,
    true,
    stepId && stepId != "null"
      ? "Variant created successfully."
      : "Step created successfully."
  );

  useEffect(() => {
    // handle API response
    if (data) {
      setFormStep(0);
      navigate(-1);
    }
  }, [data]);

  const onFormSubmit = () => {
    if (formStep === 0) {
      return setFormStep(1);
    }
    if (formStep === 1) {
      return setFormStep(2);
    }

    if (selectedMailingLists.id === 1) {
      const unlayer = emailEditorRef.current?.editor;
      let editorContent = { html: "", design: "" };
      unlayer?.exportHtml((data: any) => {
        const { design, html } = data;
        editorContent = {
          design,
          html
        };
        const payload: any = {
          subject: subject.value,
          preheader: preHeader.value,
          isEmailEditor: true
        };
        if (selectedMailingLists.id === 1) {
          payload.content = editorContent?.html;
          payload.jsonContent = editorContent?.design;
        }
        if (!stepId || stepId == "null") {
          payload.stepDay = parseInt(stepDay.value);
        }
        return callApi(payload);
      });
    } else {
      const payload: any = {
        subject: subject.value,
        preheader: preHeader.value,
        isEmailEditor: false,
        content: value
      };
      if (!stepId || stepId == "null") {
        payload.stepDay = parseInt(stepDay.value);
      }
      return callApi(payload);
    }
  };

  const onChange = (value: string) => {
    setValue(value);
  };

  return (
    <div className="p-4 md:p-8 pt-5">
      <div className="relative pb-5 sm:pb-0"></div>
      <div className="items-center align-center justify-center pb-2 m-auto w-[60%]">
        <div className="rounded-md dark:bg-boxdark">
          <div className="px-6">
            <div className="items-center ">
              <div className="space-y-1 mb-4">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Add Step
                </h3>
                <p className="max-w-2xl text-sm text-gray-500">
                  This information will be displayed publicly so be careful what
                  you share.
                </p>
              </div>
              <form>
                <nav
                  className="items-center align-center justify-center pb-2 m-auto"
                  aria-label="Progress">
                  <ol
                    role="list"
                    className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
                    {steps.map((step, stepIdx) => (
                      <li
                        key={step.name}
                        className="relative md:flex md:flex-1">
                        {formStep > step.id ? (
                          <a
                            href="#"
                            className="group flex w-full items-center">
                            <span className="flex items-center px-6 py-4 text-sm font-medium">
                              <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                                <CheckIcon
                                  className="h-6 w-6 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                              <span className="ml-4 text-sm font-medium text-gray-900">
                                {step.name}
                              </span>
                            </span>
                          </a>
                        ) : formStep === step.id ? (
                          <a
                            href="#"
                            className="flex items-center px-6 py-4 text-sm font-medium"
                            aria-current="step">
                            <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                              <span className="text-indigo-600">
                                {step.id + 1}
                              </span>
                            </span>
                            <span className="ml-4 text-sm font-medium text-indigo-600">
                              {step.name}
                            </span>
                          </a>
                        ) : (
                          <a href="#" className="group flex items-center">
                            <span className="flex items-center px-6 py-4 text-sm font-medium">
                              <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                <span className="text-gray-500 group-hover:text-gray-900">
                                  {step.id + 1}
                                </span>
                              </span>
                              <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                                {step.name}
                              </span>
                            </span>
                          </a>
                        )}

                        {stepIdx !== steps.length - 1 ? (
                          <>
                            {/* Arrow separator for lg screens and up */}
                            <div
                              className="absolute top-0 right-0 hidden h-full w-5 md:block"
                              aria-hidden="true">
                              <svg
                                className="h-full w-full text-gray-300"
                                viewBox="0 0 22 80"
                                fill="none"
                                preserveAspectRatio="none">
                                <path
                                  d="M0 -2L20 40L0 82"
                                  vectorEffect="non-scaling-stroke"
                                  stroke="currentcolor"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </>
                        ) : null}
                      </li>
                    ))}
                  </ol>
                </nav>

                <div className="flex flex-col mt-8">
                  {formStep === 0 && (
                    <>
                      <Input
                        label={"Subject"}
                        inputType={INPUT_TYPE.TEXT}
                        placeholder={"Subject"}
                        value={subject.value}
                        onChange={(value: string) =>
                          setSubject({ ...subject, value })
                        }
                        isValid={subject.isValid}
                        validationMessage={"Invalid subject"}
                      />
                      <Input
                        label={"Pre Header"}
                        inputType={INPUT_TYPE.TEXT}
                        placeholder={"Pre Header"}
                        value={preHeader.value}
                        onChange={(value: string) =>
                          setPreHeader({ ...preHeader, value })
                        }
                        isValid={preHeader.isValid}
                        validationMessage={"Invalid pre header"}
                      />
                      <Input
                        label={"Step Day"}
                        inputType={INPUT_TYPE.NUMBER}
                        placeholder={"Step Day"}
                        value={stepDay.value}
                        onChange={(value: string) =>
                          setStepDay({ ...stepDay, value })
                        }
                        isValid={stepDay.isValid}
                        validationMessage={"Invalid step day"}
                      />
                    </>
                  )}
                  {formStep === 1 && (
                    <RadioGroup
                      value={selectedMailingLists}
                      onChange={setSelectedMailingLists}>
                      <RadioGroup.Label className="text-base font-medium text-gray-900">
                        How would you like to create a template...
                      </RadioGroup.Label>

                      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                        {mailingLists.map((mailingList) => (
                          <RadioGroup.Option
                            key={mailingList.id}
                            value={mailingList}
                            className={({ checked, active }) =>
                              classNames(
                                checked
                                  ? "border-transparent"
                                  : "border-gray-300",
                                active
                                  ? "border-indigo-500 ring-2 ring-indigo-500"
                                  : "",
                                "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                              )
                            }>
                            {({ checked, active }) => (
                              <>
                                <span className="flex flex-1">
                                  <span className="flex flex-col">
                                    <RadioGroup.Label
                                      as="span"
                                      className="block text-sm font-medium text-gray-900">
                                      {mailingList.title}
                                    </RadioGroup.Label>
                                    <RadioGroup.Description
                                      as="span"
                                      className="mt-1 flex items-center text-sm text-gray-500">
                                      {mailingList.description}
                                    </RadioGroup.Description>
                                    <RadioGroup.Description
                                      as="span"
                                      className="mt-6 text-sm font-medium text-gray-900">
                                      {mailingList.users}
                                    </RadioGroup.Description>
                                  </span>
                                </span>
                                <CheckCircleIcon
                                  className={classNames(
                                    !checked ? "invisible" : "",
                                    "h-5 w-5 text-indigo-600"
                                  )}
                                  aria-hidden="true"
                                />
                                <span
                                  className={classNames(
                                    active ? "border" : "border-2",
                                    checked
                                      ? "border-indigo-500"
                                      : "border-transparent",
                                    "pointer-events-none absolute -inset-px rounded-lg"
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  )}
                  {formStep === 2 && selectedMailingLists.id === 2 && (
                    <div className="mb-2">
                      <div className="text-sm mb-2 font-bold text-gray-700 tracking-wide">
                        Content
                      </div>
                      <SlateJS
                        value={value}
                        onChange={onChange}
                        disabled={false}
                      />
                    </div>
                  )}

                  {formStep === 2 && selectedMailingLists.id === 1 && (
                    <div className="mb-2">
                      <EmailEditor ref={emailEditorRef} onReady={onReady} />
                    </div>
                  )}
                </div>

                <div className="-mx-3 flex flex-wrap gap-y-4 justify-end">
                  <div className="px-3 py-3">
                    <PrimaryButton
                      label={formStep === 0 || formStep === 1 ? "Next" : "Save"}
                      onClick={onFormSubmit}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStep;
