type InputProps = {
  label?: string;
  inputType?: string;
  placeholder?: string;
  value: string;
  onChange?: (value: string) => void;
  validationMessage?: string;
  isValid?: boolean;
  disabled?: boolean;
};
const Input = ({
  label,
  inputType = "text",
  placeholder,
  value,
  onChange,
  validationMessage,
  isValid = true,
  disabled = false
}: InputProps) => {
  return (
    <div className="sm:col-span-2 mb-4">
      {label && (
        <label
          htmlFor={label}
          className="block text-sm font-semibold leading-6 text-gray-900">
          {label}
        </label>
      )}
      <div className="mt-1.5">
        <input
          type={inputType}
          name={label}
          id={label}
          placeholder={placeholder}
          onChange={(e) => onChange && onChange(e.target.value)}
          disabled={disabled}
          value={value}
          autoComplete="organization"
          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
        {!isValid && (
          <p className="text-sm text-red-500">{validationMessage}</p>
        )}
      </div>
    </div>
  );
};

export default Input;
