// Dependencies
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// Custom Hook
import { useAPI } from "../../hooks/useApi";

// i18n translation
import { useTranslation } from "react-i18next";

// Components
import Input from "../../components/input/input";
import PrimaryButton from "../../components/button/primary-button";

// SVG Icon
import Lock from "../../assets/svg/lock-icon";
import UserInputIcon from "../../assets/svg/user-input-icon";
import Email from "../../assets/svg/email-icon";

// Constants
import {
  API,
  DEFAULT_VALUE,
  INPUT_TYPE,
  ONABOARD_PAGE,
  ROUTES,
  TRANSLATATION_KEY
} from "../../utils/contants";
import { InputFieldItems, InputProps } from "../../common-types/auth-types";

const inputFieldItems: InputFieldItems[] = [
  {
    key: 0,
    type: INPUT_TYPE.TEXT,
    name: ONABOARD_PAGE.INPUT_FIELD_KEY.FIRST_NAME,
    icon: <UserInputIcon />,
    disabled: DEFAULT_VALUE.FALSE
  },
  {
    key: 1,
    type: INPUT_TYPE.TEXT,
    name: ONABOARD_PAGE.INPUT_FIELD_KEY.LAST_NAME,
    icon: <UserInputIcon />,
    disabled: DEFAULT_VALUE.FALSE
  },
  {
    key: 2,
    type: INPUT_TYPE.TEXT,
    name: ONABOARD_PAGE.INPUT_FIELD_KEY.EMAIL,
    icon: <Email />,
    disabled: DEFAULT_VALUE.TRUE
  },
  {
    key: 3,
    type: INPUT_TYPE.NUMBER,
    name: ONABOARD_PAGE.INPUT_FIELD_KEY.PHONE_NUMBER,
    icon: <UserInputIcon />,
    disabled: DEFAULT_VALUE.FALSE
  },
  {
    key: 4,
    type: INPUT_TYPE.PASSWORD,
    name: ONABOARD_PAGE.INPUT_FIELD_KEY.PASSWORD,
    icon: <Lock />,
    disabled: DEFAULT_VALUE.FALSE
  }
];

const Onboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { email } = useParams();
  const userEmail: string = email || DEFAULT_VALUE.EMPTY;
  const [firstName, setFirstName] = useState<InputProps>({
    value: DEFAULT_VALUE.EMPTY,
    isValid: DEFAULT_VALUE.TRUE
  });
  const [lastName, setLastName] = useState<InputProps>({
    value: DEFAULT_VALUE.EMPTY,
    isValid: DEFAULT_VALUE.TRUE
  });
  const [phoneNumber, setPhoneNumber] = useState<InputProps>({
    value: DEFAULT_VALUE.EMPTY,
    isValid: DEFAULT_VALUE.TRUE
  });
  const [password, setPassword] = useState<InputProps>({
    value: DEFAULT_VALUE.EMPTY,
    isValid: DEFAULT_VALUE.TRUE
  });
  const { data, isLoading, callApi } = useAPI(
    API.ROUTE.ONBOARD,
    API.METHOD.POST,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE,
    t(TRANSLATATION_KEY.ONBOARD.API_SUCCESS_MSG)
  );

  useEffect(() => {
    if (data) {
      navigate(ROUTES.LOGIN);
    }
  }, [data]);

  const getFieldValue = (fieldName: string) => {
    switch (fieldName) {
      case ONABOARD_PAGE.INPUT_FIELD_KEY.FIRST_NAME:
        return firstName.value;
      case ONABOARD_PAGE.INPUT_FIELD_KEY.LAST_NAME:
        return lastName.value;
      case ONABOARD_PAGE.INPUT_FIELD_KEY.EMAIL:
        return userEmail;
      case ONABOARD_PAGE.INPUT_FIELD_KEY.PHONE_NUMBER:
        return phoneNumber.value;
      case ONABOARD_PAGE.INPUT_FIELD_KEY.PASSWORD:
        return password.value;
      default:
        return DEFAULT_VALUE.EMPTY;
    }
  };

  const getIsFieldValid = (fieldName: string) => {
    switch (fieldName) {
      case ONABOARD_PAGE.INPUT_FIELD_KEY.FIRST_NAME:
        return firstName.isValid;
      case ONABOARD_PAGE.INPUT_FIELD_KEY.LAST_NAME:
        return lastName.isValid;
      case ONABOARD_PAGE.INPUT_FIELD_KEY.EMAIL:
        return true;
      case ONABOARD_PAGE.INPUT_FIELD_KEY.PHONE_NUMBER:
        return phoneNumber.isValid;
      case ONABOARD_PAGE.INPUT_FIELD_KEY.PASSWORD:
        return password.isValid;
      default:
        return DEFAULT_VALUE.TRUE;
    }
  };

  const setFieldValue = (fieldName: string, value: string) => {
    switch (fieldName) {
      case ONABOARD_PAGE.INPUT_FIELD_KEY.FIRST_NAME:
        return setFirstName({ ...firstName, value });
      case ONABOARD_PAGE.INPUT_FIELD_KEY.LAST_NAME:
        return setLastName({ ...firstName, value });
      case ONABOARD_PAGE.INPUT_FIELD_KEY.EMAIL:
        return null;
      case ONABOARD_PAGE.INPUT_FIELD_KEY.PHONE_NUMBER:
        return setPhoneNumber({ ...firstName, value });
      case ONABOARD_PAGE.INPUT_FIELD_KEY.PASSWORD:
        return setPassword({ ...firstName, value });
      default:
        return DEFAULT_VALUE.NULL;
    }
  };

  const onFormSubmit = () => {
    if (isLoading) return;
    if (!firstName.value) return setFirstName({ ...firstName, isValid: false });
    if (!lastName.value) return setLastName({ ...lastName, isValid: false });
    if (!phoneNumber.value)
      return setPhoneNumber({ ...phoneNumber, isValid: false });
    if (!password.value) return setPassword({ ...password, isValid: false });

    setFirstName({ ...firstName, isValid: true });
    setLastName({ ...lastName, isValid: true });
    setPhoneNumber({ ...phoneNumber, isValid: true });
    setPassword({ ...password, isValid: true });

    const payload = {
      firstName: firstName.value,
      lastName: lastName.value,
      phone: phoneNumber.value,
      password: password.value,
      email
    };
    callApi(payload);
  };

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="lg:flex">
        <div className="hidden lg:flex items-center justify-center bg-indigo-100 flex-1 h-screen">
          <div className="py-12 bg-indigo-100 lg:bg-white flex justify-center lg:justify-start lg:px-12">
            <div className="cursor-pointer flex items-center">
              <div></div>
              <div className="text-2xl text-black tracking-wide ml-2 font-semibold">
                {t(TRANSLATATION_KEY.APP.NAME)}
              </div>
            </div>
          </div>
          <div className="max-w-xs transform duration-200 hover:scale-110 cursor-pointer"></div>
        </div>
        <div className="lg:w-2/3 flex justify-center items-center">
          <div className="w-full lg:w-2/3 px-12 sm:px-24 md:px-48 lg:px-12 xl:px-24 xl:max-w-2xl">
            <h2
              className="text-center text-xl text-black font-display font-semibold lg:text-left xl:text-2xl
                    xl:text-bold">
              {t(TRANSLATATION_KEY.ONBOARD.FORM.TITLE)}
            </h2>
            <div className="mt-12">
              <form>
                {inputFieldItems.length &&
                  inputFieldItems.map((field: any) => (
                    <Input
                      key={field.key}
                      label={t(
                        TRANSLATATION_KEY.ONBOARD.FORM[field.name].LABEL
                      )}
                      inputType={field.type}
                      placeholder={t(
                        TRANSLATATION_KEY.ONBOARD.FORM[field.name].PLACEHOLDER
                      )}
                      value={getFieldValue(field.name)}
                      onChange={(value: string) =>
                        setFieldValue(field.name, value)
                      }
                      isValid={getIsFieldValid(field.name)}
                      validationMessage={t(
                        TRANSLATATION_KEY.ONBOARD.FORM[field.name]
                          .VALIDATION_MSG
                      )}
                      disabled={field.disabled}
                    />
                  ))}
                <div className="mt-10">
                  <PrimaryButton
                    label={t(TRANSLATATION_KEY.ONBOARD.BUTTON)}
                    onClick={onFormSubmit}
                    isLoading={isLoading}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboard;
