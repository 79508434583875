import { MouseEvent } from "react";

type SecondaryButtonProps = {
  label: string;
  onClick: () => void;
};
const SecondaryButton = ({ label, onClick }: SecondaryButtonProps) => {
  const onClickHandler = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    onClick();
  };
  return (
    <button
      className="block w-full rounded border border-stroke bg-gray p-3 py-4 px-5 text-center font-medium text-black transition hover:border-boxdark hover:bg-boxdark hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
      onClick={onClickHandler}>
      {label}
    </button>
  );
};

export default SecondaryButton;
