// Dependencies
import { ReactNode, MouseEvent, useEffect } from "react";

// Hooks
import { useAPI } from "../../hooks/useApi";

// Constants
import { ACCOUNT_TYPE, API } from "../../utils/contants";

type AccountTypeProps = {
  name: string;
  subTitle: string;
  icon: ReactNode;
};

const AccountTypeCard = ({
  accountType,
  closeModal
}: {
  accountType: AccountTypeProps;
  closeModal: (value: boolean) => void;
}) => {
  const { data, callApi } = useAPI(
    API.ROUTE.CONNECT_SOCIAL_ACCOUNT + accountType.name.toLowerCase(),
    API.METHOD.GET,
    null,
    true
  );

  useEffect(() => {
    if (data) {
      const { payload }: any = data;
      window.location.href = payload.connectionUrl;
    }
  }, [data]);

  const connectEmailHandler = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (
      accountType.name.toLowerCase() == ACCOUNT_TYPE.GOOGLE ||
      accountType.name.toLowerCase() == ACCOUNT_TYPE.MICROSOFT
    ) {
      callApi();
    } else {
      // close modal to show SMTP/IMAP form
      closeModal(true);
    }
  };
  return (
    <div
      className="bg-gray cursor-pointer text-center rounded-[10px] border border-stroke hover:border-blue-600 pb-5 pt-5 dark:border-strokedark"
      onClick={connectEmailHandler}>
      <div className="text-center m-auto mx-auto w-full">
        {accountType.icon}
      </div>
      <div className="mt-2 text-center">
        <h4 className="mb-0.5 text-sm font-bold text-black dark:text-white">
          {accountType.name}
        </h4>
        <p className="text-xs px-2">{accountType.subTitle}</p>
      </div>
    </div>
  );
};

export default AccountTypeCard;
