import { Navigate } from "react-router-dom";

const DefaultRoute = () => {
  const authToken = localStorage.getItem("token");
  const isAuthenticated = authToken ? true : false;

  return isAuthenticated ? (
    <Navigate to="/sequence" />
  ) : (
    <Navigate to="/login" />
  );
};
export default DefaultRoute;
