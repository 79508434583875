// Dependencies
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Components
import Drawer from "../../components/drawer/drawer";
import AddScheduleDrawer from "./add-schedule-drawer";

// Custom Hook
import { useAPI } from "../../hooks/useApi";

// Constants
import {
  API,
  DEFAULT_VALUE,
  DRAWER_WIDTH,
  ROUTES,
  TRANSLATATION_KEY
} from "../../utils/contants";

// i18n Translation
import { useTranslation } from "react-i18next";

// Types
import { DrawerWidthType } from "../../common-types/common-type";
import PageHeading from "../../components/page-heading/page-heading";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import DeleteConfirmation from "../../components/modal/delete-confirmation";
import TableSkeletonLoading from "../../components/table/table-skeleton-loading";
import NoData from "../../components/no-data/no-data";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Schedules = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [schedules, setSchedule] = useState<any[]>([]);
  const [editId, setEditId] = useState(DEFAULT_VALUE.NULL);
  const [page] = useState<number>(1);
  const [showEditModal, setShowEditModal] = useState<boolean>(
    DEFAULT_VALUE.FALSE
  );
  const [deleteModal, setDeleteModal] = useState<boolean>(DEFAULT_VALUE.FALSE);
  const [deleteId, setDeleteId] = useState(DEFAULT_VALUE.NULL);

  const { data, isLoading, callApi } = useAPI(
    `${API.ROUTE.SCHEDULE}`,
    API.METHOD.GET,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE
  );

  const addSequence = useAPI(
    API.ROUTE.SEQUENCE,
    API.METHOD.POST,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE
  );

  const deleteSequence = useAPI(
    `${API.ROUTE.SCHEDULE}/${deleteId}`,
    API.METHOD.DELETE,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE,
    t(TRANSLATATION_KEY.SCHEDULE.FORM.API_DELETE_SUCCESS_MSG)
  );

  useEffect(() => {
    callApi();
  }, [page]);

  useEffect(() => {
    if (data) {
      const { payload }: any = data;
      setSchedule(payload?.schedules);
    }
  }, [data]);

  useEffect(() => {
    if (addSequence.data) {
      const { payload }: any = addSequence.data;
      navigate(ROUTES.STEP + "/" + payload.sequenceId);
    }
  }, [addSequence.data]);

  useEffect(() => {
    if (deleteSequence.data) {
      callApi();
    }
  }, [deleteSequence.data]);

  const toggleModalHandler = () => {
    setShowEditModal(DEFAULT_VALUE.TRUE);
  };

  const toggleEditModalHandler = () => {
    setEditId(DEFAULT_VALUE.NULL);
    setShowEditModal(DEFAULT_VALUE.FALSE);
    callApi();
  };

  const getEditData = (id: any) => {
    return id
      ? schedules.find((seq: any) => seq._id === id)
      : DEFAULT_VALUE.NULL;
  };

  const deleteCampaignHandler = () => {
    console.log(deleteId);
    deleteSequence.callApi();
    setDeleteId(null);
    setDeleteModal(false);
  };

  return (
    <>
      <PageHeading
        title="Schedule"
        subTitle="Create schedules to manage weekly timeline"
        showAddBtn={true}
        btnTitle="Create Schedule"
        onBtnClick={toggleModalHandler}
      />

      <DeleteConfirmation
        open={deleteModal}
        title="Schedule"
        onChange={() => setDeleteModal(!deleteModal)}
        onDelete={deleteCampaignHandler}
      />

      <div>
        <div className="p-4 md:p-8 flow-root">
          <ul role="list" className="-my-5">
            <li className="py-4 px-4 bg-[#f3f4f6] rounded-lg">
              <div className="flex items-center space-x-4">
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-bold text-gray-900">
                    Name
                  </p>
                </div>
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-bold text-gray-900">
                    Timezone
                  </p>
                </div>
                <div className="min-w-0">
                  <p className="truncate text-sm font-bold text-gray-900">
                    Action
                  </p>
                </div>
              </div>
            </li>
            {!isLoading &&
              schedules.length > 0 &&
              schedules.map((schedule: any) => (
                <li
                  key={schedule._id}
                  className="py-2 px-4 border mt-2 border-[#e2e8f0] rounded-md hover:bg-[#f3f4f6]">
                  <div className="flex items-center space-x-4">
                    <div className="min-w-0 flex-1">
                      <p className="truncate text-sm font-medium text-gray-900">
                        {schedule.name}
                      </p>
                    </div>
                    <div className="min-w-0 flex-1">
                      <p className="truncate text-sm font-medium text-gray-900">
                        {schedule.timeZone}
                      </p>
                    </div>
                    <div>
                      <Menu
                        as="div"
                        className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95">
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setEditId(schedule._id);
                                      setShowEditModal(DEFAULT_VALUE.TRUE);
                                    }}
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm"
                                    )}>
                                    Edit
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      setDeleteId(schedule._id);
                                      setDeleteModal(true);
                                    }}
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm"
                                    )}>
                                    Delete
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </li>
              ))}

            {!isLoading && schedules.length <= 0 && (
              <div className="w-full bg-[#f3f4f6] mt-2 rounded-lg py-4">
                <NoData buttonName="Schedule" onAdd={toggleModalHandler} />
              </div>
            )}

            {isLoading && (
              <div className="w-full">
                <TableSkeletonLoading />
              </div>
            )}
          </ul>
        </div>
      </div>
      <Drawer
        title={t(TRANSLATATION_KEY.SCHEDULE.ADD_TITLE)}
        showModal={showEditModal}
        width={DRAWER_WIDTH.MIN as DrawerWidthType}
        onCloseModal={toggleEditModalHandler}>
        <AddScheduleDrawer
          editId={editId}
          editData={getEditData(editId)}
          closeModal={() => {
            toggleEditModalHandler();
            callApi();
          }}
        />
      </Drawer>
    </>
  );
};

export default Schedules;
