// Dependencies
import { useEffect, useState } from "react";

// Custom Hook
import { useAPI } from "../../hooks/useApi";

// i18n translation
import { useTranslation } from "react-i18next";

// Components
import Input from "../../components/input/input";
import PrimaryButton from "../../components/button/primary-button";

// Constants
import {
  API,
  DEFAULT_VALUE,
  INPUT_TYPE,
  TRANSLATATION_KEY
} from "../../utils/contants";

// Types
import { InputProps } from "../../common-types/auth-types";

const EditSequence = ({
  editData,
  closeModal
}: {
  editData: { id: number; label: string };
  closeModal: () => void;
}) => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(DEFAULT_VALUE.FALSE);
  const [newLabel, setLabel] = useState<InputProps>({
    value: editData.label,
    isValid: DEFAULT_VALUE.TRUE
  });

  // API Hook
  const { data, isLoading, callApi } = useAPI(
    `${API.ROUTE.SEQUENCE}/${editData ? editData.id : DEFAULT_VALUE.NULL}`,
    API.METHOD.PATCH,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE,
    t(TRANSLATATION_KEY.SEQUENCE.FORM.UPDATE_SEQUENCE_API_SUCCESS_MSG)
  );

  useEffect(() => {
    setShowForm(DEFAULT_VALUE.TRUE);
  });

  useEffect(() => {
    // handle API response
    if (data) {
      closeModal();
    }
  }, [data]);

  const onFormSubmit = () => {
    // validate form

    // submit form
    const payload = {
      name: newLabel.value
    };
    callApi(payload);
  };

  return (
    <div className="w-full">
      <div>
        <div className="rounded-md">
          <div className="w-full">
            <div className="items-center justify-between gap-5">
              {showForm && (
                <form>
                  <div className="">
                    <Input
                      label={t(
                        TRANSLATATION_KEY.SEQUENCE.FORM.LABEL_FIELD.LABEL
                      )}
                      inputType={INPUT_TYPE.TEXT}
                      placeholder={t(
                        TRANSLATATION_KEY.SEQUENCE.FORM.LABEL_FIELD.PLACEHOLDER
                      )}
                      value={newLabel.value}
                      onChange={(value: string) =>
                        setLabel({ ...newLabel, value })
                      }
                      isValid={newLabel.isValid}
                      validationMessage={t(
                        TRANSLATATION_KEY.SEQUENCE.FORM.LABEL_FIELD
                          .VALIDATION_MSG
                      )}
                    />
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="-mx-3 flex flex-wrap gap-y-4 justify-end">
        <div className="px-3 py-3">
          <PrimaryButton
            label={t(TRANSLATATION_KEY.SEQUENCE.BUTTON.SAVE)}
            onClick={onFormSubmit}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default EditSequence;
