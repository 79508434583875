import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { Popover } from "@headlessui/react";
import { Link } from "react-router-dom";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const user = {
  name: "Whitney Francis",
  email: "whitney.francis@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
};

const DropdownUser = () => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? "text-gray-900" : "text-gray-500",
              "group"
            )}>
            <a
              href="#"
              className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2">
              <span className="sr-only">Open user menu</span>
              <img
                className="h-8 w-8 rounded-full"
                src={user.imageUrl}
                alt=""
              />
            </a>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <Popover.Panel className="absolute left-0 z-10 mt-3 max-w-xs -translate-x-3/4 transform px-2 sm:px-0">
              <div className="overflow-hidden mt-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="bg-[#f9fafb] px-4 py-4">
                  <div>
                    <div className="flex flex-wrap text-sm">
                      <h3 className="flex-1 text-sm text-gray-500">Profile</h3>
                    </div>
                    <ul
                      role="list"
                      className="mt-2 bg-white space-y-2 divide-[#e2e8f0] divide-y">
                      <li className="pt-3 px-4 truncate text-sm">
                        <Link
                          to="/profile"
                          className="font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-700">
                          Your Profile
                        </Link>
                      </li>
                      <li className="px-4 py-3 truncate text-sm">
                        <Link
                          to="/login"
                          onClick={() => localStorage.removeItem("token")}
                          className="font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-700">
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default DropdownUser;
