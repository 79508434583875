// Dependencies
import { useEffect, useState } from "react";

// Custom Hook
import { useAPI } from "../../hooks/useApi";

// i18n translation
import { useTranslation } from "react-i18next";

// Components
import Input from "../../components/input/input";
import PrimaryButton from "../../components/button/primary-button";
import SecondaryButton from "../../components/button/secondary-button";

// Constants
import { API, INPUT_TYPE } from "../../utils/contants";

type ProviderProps = {
  name: string;
};

type InputProps = {
  value: string;
  isValid: boolean;
};

const options: ProviderProps[] = [
  {
    name: "Gmail"
  }
];

const AddEmailBySmtp = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation();
  const [esp, setEsp] = useState<InputProps>({
    value: "gmail",
    isValid: false
  });
  const [fromName, setFromName] = useState<InputProps>({
    value: "",
    isValid: true
  });

  // SMTP form
  const [sSenderName, setSsenderName] = useState<InputProps>({
    value: "",
    isValid: true
  });
  const [sEmail, setSemail] = useState<InputProps>({
    value: "",
    isValid: true
  });
  const [sPassword, setSpassword] = useState<InputProps>({
    value: "",
    isValid: true
  });
  const [sSmtpHost, setSsmtpHost] = useState<InputProps>({
    value: "",
    isValid: true
  });
  const [sSmtpPort, setSsmtpPort] = useState<InputProps>({
    value: "",
    isValid: true
  });
  const [sEncryption, setSencryption] = useState<InputProps>({
    value: "ssl",
    isValid: true
  });

  // IMAP form
  const [iSenderName, setIsenderName] = useState<InputProps>({
    value: "",
    isValid: true
  });
  const [iEmail, setIemail] = useState<InputProps>({
    value: "",
    isValid: true
  });
  const [iPassword, setIpassword] = useState<InputProps>({
    value: "",
    isValid: true
  });
  const [iSmtpHost, setIsmtpHost] = useState<InputProps>({
    value: "",
    isValid: true
  });
  const [iSmtpPort, setIsmtpPort] = useState<InputProps>({
    value: "",
    isValid: true
  });
  const [iEncryption, setIencryption] = useState<InputProps>({
    value: "ssl",
    isValid: true
  });

  // API Hook
  const { data, isLoading, callApi } = useAPI(
    API.ROUTE.CONNECT_SMTP_ACCOUNT,
    API.METHOD.POST,
    null,
    true
  );

  useEffect(() => {
    // handle API response
    if (data) {
      closeModal();
    }
  }, [data]);

  const onFormSubmit = () => {
    // validate form
    if (isLoading) return;

    // submit form
    const payload = {
      esp: "google",
      fromName: fromName.value,
      smtp: {
        userName: sSenderName.value,
        email: sEmail.value,
        password: sPassword.value,
        host: sSmtpHost.value,
        port: sSmtpPort.value,
        encryptionType: sEncryption.value
      },
      imap: {
        userName: iSenderName.value,
        email: iEmail.value,
        password: iPassword.value,
        host: iSmtpHost.value,
        port: iSmtpPort.value,
        encryptionType: iEncryption.value
      }
    };
    callApi(payload);
  };

  return (
    <div className="w-full" style={{ height: "75vh" }}>
      <div className="overflow-scroll" style={{ height: "67.5vh" }}>
        <div className="rounded-md shadow-default dark:bg-boxdark">
          <div className="p-5 px-7.5">
            <h4 className="text-xl font-semibold text-black hover:text-primary dark:text-white dark:hover:text-primary">
              <a>SMTP Details</a>
            </h4>
          </div>
          <div className="px-7.5">
            <div className="flex flex-wrap items-center justify-between gap-5">
              <form>
                <div className="flex flex-col gap-6 xl:flex-row">
                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      {t("smtp.form.emailProvider")}
                    </label>
                    <div className="relative z-20 bg-transparent dark:bg-form-input">
                      <select
                        value={esp.value}
                        onChange={(e) =>
                          setEsp({ ...esp, value: e.target.value })
                        }
                        className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 pr-12 pl-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary ">
                        {options.length &&
                          options.map(
                            (option: ProviderProps, index: number) => (
                              <option
                                key={index}
                                value={option.name.toLowerCase()}
                                className="text-body dark:text-bodydark">
                                {option.name}
                              </option>
                            )
                          )}
                      </select>
                      <span className="absolute top-1/2 right-3 z-30 -translate-y-1/2">
                        <svg
                          className="fill-current"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <g opacity="0.8">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                              fill=""></path>
                          </g>
                        </svg>
                      </span>
                    </div>
                    {!esp.isValid && (
                      <p className="text-sm text-red-500">
                        Please select service provider
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex flex-col gap-6 xl:flex-row">
                  <Input
                    label={t("smtp.form.fromName")}
                    inputType={INPUT_TYPE.TEXT}
                    placeholder={t("login.form.emailPlaceholder")}
                    value={fromName.value}
                    onChange={(value: string) =>
                      setFromName({ ...fromName, value })
                    }
                    isValid={fromName.isValid}
                    validationMessage={t("login.form.emailValidationMessage")}
                  />
                  <Input
                    label={t("smtp.form.senderName")}
                    inputType={INPUT_TYPE.TEXT}
                    placeholder={t("login.form.emailPlaceholder")}
                    value={sSenderName.value}
                    onChange={(value: string) =>
                      setSsenderName({ ...sSenderName, value })
                    }
                    isValid={sSenderName.isValid}
                    validationMessage={t("login.form.emailValidationMessage")}
                  />
                </div>

                <div className="flex flex-col gap-6 xl:flex-row">
                  <Input
                    label={t("smtp.form.email")}
                    inputType={INPUT_TYPE.EMAIL}
                    placeholder={t("login.form.emailPlaceholder")}
                    value={sEmail.value}
                    onChange={(value: string) =>
                      setSemail({ ...sEmail, value })
                    }
                    isValid={sEmail.isValid}
                    validationMessage={t("login.form.emailValidationMessage")}
                  />
                  <Input
                    label={t("smtp.form.password")}
                    inputType={INPUT_TYPE.PASSWORD}
                    placeholder={t("login.form.emailPlaceholder")}
                    value={sPassword.value}
                    onChange={(value: string) =>
                      setSpassword({ ...sPassword, value })
                    }
                    isValid={sPassword.isValid}
                    validationMessage={t("login.form.emailValidationMessage")}
                  />
                </div>

                <div className="flex flex-col gap-6 xl:flex-row">
                  <Input
                    label={t("smtp.form.smtpHost")}
                    inputType={INPUT_TYPE.TEXT}
                    placeholder={t("login.form.emailPlaceholder")}
                    value={sSmtpHost.value}
                    onChange={(value: string) =>
                      setSsmtpHost({ ...sSmtpHost, value })
                    }
                    isValid={sSmtpHost.isValid}
                    validationMessage={t("login.form.emailValidationMessage")}
                  />
                  <div className="flex flex-col gap-6 xl:flex-row">
                    <Input
                      label={t("smtp.form.smtpPort")}
                      inputType={INPUT_TYPE.EMAIL}
                      placeholder={t("login.form.emailPlaceholder")}
                      value={sSmtpPort.value}
                      onChange={(value: string) =>
                        setSsmtpPort({ ...sSmtpPort, value })
                      }
                      isValid={sSmtpPort.isValid}
                      validationMessage={t("login.form.emailValidationMessage")}
                    />
                    <div className="items-center rounded-lg">
                      <label className="mb-2.5 block font-medium text-black dark:text-white">
                        Encryption
                      </label>
                      <div className="flex mt-4 items-center rounded-lg">
                        <a
                          onClick={() =>
                            setSencryption({ ...sEncryption, value: "ssl" })
                          }
                          className={`inline-flex rounded-l-lg border ${sEncryption.value == "ssl" ? "border-primary text-primary" : "border-stroke text-bodydark2"} py-1 px-2 font-medium hover:border-primary hover:text-primary dark:hover:border-primary sm:py-3 sm:px-6`}>
                          SSL
                        </a>
                        <a
                          onClick={() =>
                            setSencryption({ ...sEncryption, value: "tls" })
                          }
                          className={`inline-flex border py-1 px-2 font-medium hover:border-primary hover:text-primary dark:hover:border-primary sm:py-3 sm:px-6 ${sEncryption.value == "tls" ? "border-primary text-primary" : "border-stroke text-bodydark2"}`}>
                          TSL
                        </a>
                        <a
                          onClick={() =>
                            setSencryption({ ...sEncryption, value: "none" })
                          }
                          className={`inline-flex rounded-r-lg border py-1 px-2 font-medium hover:border-primary hover:text-primary dark:hover:border-primary sm:py-3 sm:px-6 ${sEncryption.value == "none" ? "border-primary text-primary" : "border-stroke text-bodydark2"}`}>
                          None
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="rounded-md bg-white shadow-default dark:bg-boxdark">
          <div className="p-5 px-7.5">
            <h4 className="text-xl font-semibold text-black hover:text-primary dark:text-white dark:hover:text-primary">
              <a>IMAP Details</a>
            </h4>
          </div>
          <div className="px-7.5 pt-6 pb-9">
            <form>
              <div className="flex flex-col gap-6 xl:flex-row">
                <Input
                  label={t("smtp.form.senderName")}
                  inputType={INPUT_TYPE.TEXT}
                  placeholder={t("login.form.emailPlaceholder")}
                  value={iSenderName.value}
                  onChange={(value: string) =>
                    setIsenderName({ ...iSenderName, value })
                  }
                  isValid={iSenderName.isValid}
                  validationMessage={t("login.form.emailValidationMessage")}
                />
              </div>

              <div className="flex flex-col gap-6 xl:flex-row">
                <Input
                  label={t("smtp.form.email")}
                  inputType={INPUT_TYPE.EMAIL}
                  placeholder={t("login.form.emailPlaceholder")}
                  value={iEmail.value}
                  onChange={(value: string) => setIemail({ ...iEmail, value })}
                  isValid={iEmail.isValid}
                  validationMessage={t("login.form.emailValidationMessage")}
                />
                <Input
                  label={t("smtp.form.password")}
                  inputType={INPUT_TYPE.PASSWORD}
                  placeholder={t("login.form.emailPlaceholder")}
                  value={iPassword.value}
                  onChange={(value: string) =>
                    setIpassword({ ...iPassword, value })
                  }
                  isValid={iPassword.isValid}
                  validationMessage={t("login.form.emailValidationMessage")}
                />
              </div>

              <div className="flex flex-col gap-6 xl:flex-row">
                <Input
                  label={t("smtp.form.smtpHost")}
                  inputType={INPUT_TYPE.TEXT}
                  placeholder={t("login.form.emailPlaceholder")}
                  value={iSmtpHost.value}
                  onChange={(value: string) =>
                    setIsmtpHost({ ...iSmtpHost, value })
                  }
                  isValid={iSmtpHost.isValid}
                  validationMessage={t("login.form.emailValidationMessage")}
                />
                <div className="flex flex-col gap-6 xl:flex-row">
                  <Input
                    label={t("smtp.form.smtpPort")}
                    inputType={INPUT_TYPE.TEXT}
                    placeholder={t("login.form.emailPlaceholder")}
                    value={iSmtpPort.value}
                    onChange={(value: string) =>
                      setIsmtpPort({ ...iSmtpPort, value })
                    }
                    isValid={iSmtpPort.isValid}
                    validationMessage={t("login.form.emailValidationMessage")}
                  />
                  <div className="items-center rounded-lg">
                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                      Encryption
                    </label>
                    <div className="flex mt-4 items-center rounded-lg">
                      <a
                        onClick={() =>
                          setIencryption({ ...iEncryption, value: "ssl" })
                        }
                        className={`inline-flex rounded-l-lg border ${iEncryption.value == "ssl" ? "border-primary text-primary" : "border-stroke text-bodydark2"} py-1 px-2 font-medium hover:border-primary hover:text-primary dark:hover:border-primary sm:py-3 sm:px-6`}>
                        SSL
                      </a>
                      <a
                        onClick={() =>
                          setIencryption({ ...iEncryption, value: "tls" })
                        }
                        className={`inline-flex border py-1 px-2 font-medium hover:border-primary hover:text-primary dark:hover:border-primary sm:py-3 sm:px-6 ${iEncryption.value == "tls" ? "border-primary text-primary" : "border-stroke text-bodydark2"}`}>
                        TSL
                      </a>
                      <a
                        onClick={() =>
                          setIencryption({ ...iEncryption, value: "none" })
                        }
                        className={`inline-flex rounded-r-lg border py-1 px-2 font-medium hover:border-primary hover:text-primary dark:hover:border-primary sm:py-3 sm:px-6 ${iEncryption.value == "none" ? "border-primary text-primary" : "border-stroke text-bodydark2"}`}>
                        None
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="-mx-3 flex flex-wrap gap-y-4 justify-end">
        <div className="px-3 py-3">
          <SecondaryButton
            label={t("smtpImap.action.cancelBtn")}
            onClick={closeModal}
          />
        </div>
        <div className="px-3 py-3">
          <PrimaryButton
            label={t("smtpImap.action.connetBtn")}
            onClick={onFormSubmit}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default AddEmailBySmtp;
