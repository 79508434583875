import { combineReducers } from "redux";
import { useSelector, TypedUseSelectorHook } from "react-redux";

// Reducers
import { notificationReducer } from "../components/notification/notification.reducer";

export const rootReducer = combineReducers({
  notification: notificationReducer
});

export type IRootState = ReturnType<typeof rootReducer>;

export const useStateSelector: TypedUseSelectorHook<IRootState> = useSelector;
