import Next from "../../assets/svg/next-icon";
import Previous from "../../assets/svg/previous-icon";

type MetaProps = {
  page: number;
  pageSize: number;
  totalCount: number;
};

const TableFooter = ({
  meta,
  onPageChange
}: {
  meta: MetaProps | null;
  onPageChange: (pageNumber: number) => void;
}) => {
  return (
    <div className="flex justify-between">
      {/* <p className="font-medium">
        Showing {meta?.page} of{" "}
        {meta && Math.ceil(meta.totalCount / meta.pageSize)} page
        {meta && Math.ceil(meta.totalCount / meta.pageSize) > 1 && "s"}
      </p> */}
      <div className="flex items-center m-auto">
        {meta && meta.page > 1 && (
          <button
            className="flex cursor-pointer items-center justify-center rounded-md p-1 px-2 hover:bg-primary hover:text-white"
            onClick={() => onPageChange(meta.page - 1)}>
            <Previous />
          </button>
        )}
        {meta &&
          [...Array(Math.ceil(meta.totalCount / meta.pageSize))].map(
            (_, index) => (
              <button
                key={index}
                className={`${(meta && meta.page) === index + 1 ? "bg-primary text-white" : "false"}  mx-1 flex cursor-pointer items-center justify-center rounded-md p-1 px-3 hover:bg-primary hover:text-white`}
                onClick={() => onPageChange(index + 1)}>
                {index + 1}
              </button>
            )
          )}
        {meta && Math.ceil(meta.totalCount / meta.pageSize) > 1 && (
          <button
            className="flex cursor-pointer items-center justify-center rounded-md p-1 px-2 hover:bg-primary hover:text-white"
            onClick={() => onPageChange(meta.page + 1)}>
            <Next />
          </button>
        )}
      </div>
    </div>
  );
};

export default TableFooter;
