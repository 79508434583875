import { createBrowserRouter } from "react-router-dom";

// Layout
import { AdminLayout } from "../components/layouts/AdminLayout";
import { SettingLayout } from "../components/layouts/setting-layout";

// Pages
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import Settings from "../pages/settings/settings";
import Onboard from "../pages/auth/onboard";
import EditEmailAccount from "../pages/settings/edit-email-account/edit-email-account";
import VerifyEmailAccount from "../pages/settings/verify-email-account";
import CustomFields from "../pages/custom-fields/custom-fields";
import Leads from "../pages/leads/leads";
import Sequence from "../pages/sequence/sequence";

// Components
import PrivateRoute from "../components/routes/private-routes";
import DefaultRoute from "../components/routes/default-route";
import ViewSequence from "../pages/sequence/view-sequence";
import Schedules from "../pages/schedule/schedules";
import AddStep from "../pages/sequence/step/add-step";
import OverallReport from "../pages/report/report";

export const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <AdminLayout />,
    errorElement: <div>Oops ! The page is broken !</div>,
    children: [
      {
        path: "",
        element: <DefaultRoute />
      },
      {
        path: "sequence",
        element: <PrivateRoute Component={Sequence} />
      },
      {
        path: "schedule",
        element: <PrivateRoute Component={Schedules} />
      },
      {
        path: "step/:type/:id",
        element: <PrivateRoute Component={ViewSequence} />
      },
      {
        path: "step/:type/:id/:stepId",
        element: <PrivateRoute Component={AddStep} />
      },
      {
        path: "leads",
        element: <PrivateRoute Component={Leads} />
      },
      {
        path: "settings",
        element: <SettingLayout />,
        children: [
          {
            path: "",
            element: <PrivateRoute Component={Settings} />
          },
          {
            path: "email-account/edit/:id",
            element: <PrivateRoute Component={EditEmailAccount} />
          }
        ]
      },
      {
        path: "/report",
        element: <PrivateRoute Component={OverallReport} />
      },
      {
        path: "/verify/:type",
        element: <PrivateRoute Component={VerifyEmailAccount} />
      },
      {
        path: "custom-fields",
        element: <SettingLayout />,
        children: [
          {
            path: "",
            element: <PrivateRoute Component={CustomFields} />
          }
        ]
      }
    ]
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/register",
    element: <Register />
  },
  {
    path: "/onboard/:email",
    element: <Onboard />
  }
]);
