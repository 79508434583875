import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

const Popover = ({
  label,
  value,
  isCompleted
}: {
  label: string;
  value: string;
  isCompleted: boolean;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <button
        type="button"
        onMouseOver={() => setOpen(true)}
        onMouseOut={() =>
          setTimeout(() => {
            setOpen(false);
          }, 2000)
        }
        className={`relative inline-flex items-center rounded-l-md  bg-white px-2 py-2 text-sm font-medium ${isCompleted ? "text-[#6366f1]" : "text-gray-500"} hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500`}>
        <span className="sr-only">Email Opened</span>
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
      </button>
      <div
        data-popover
        id="popover-description"
        role="tooltip"
        className={`absolute ${open ? "" : "invisible"} z-10 top-[-6px] inline-block text-sm text-[#6b7280] transition-opacity duration-300 bg-white border border-[#e5e7eb] rounded-lg shadow-sm opacity-100 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400`}>
        <div className="p-3 space-y-2">
          <h3 className="font-semibold text-[#111827] dark:text-white">
            {label}:
          </h3>
          <p>{value}</p>
        </div>
        <div data-popper-arrow></div>
      </div>
    </>
  );
};

export default Popover;
