// Dependencies
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Components
import Drawer from "../../components/drawer/drawer";
import EditSequence from "./edit-sequence";

// Custom Hook
import { useAPI } from "../../hooks/useApi";

// i18n Translation
import { useTranslation } from "react-i18next";

// Constants
import {
  API,
  DEFAULT_VALUE,
  DRAWER_WIDTH,
  ROUTES,
  TABLE,
  TRANSLATATION_KEY
} from "../../utils/contants";

// Types
import { DrawerWidthType } from "../../common-types/common-type";
import PageHeading from "../../components/page-heading/page-heading";
import TableStatus from "../../components/table/table-status";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import DeleteConfirmation from "../../components/modal/delete-confirmation";
import TableSkeletonLoading from "../../components/table/table-skeleton-loading";
import NoData from "../../components/no-data/no-data";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Sequence = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sequences, setSequences] = useState<any[]>([]);
  const [page] = useState<number>(1);
  const [deleteId, setDeleteId] = useState(DEFAULT_VALUE.NULL);
  const [searchValue] = useState<string>(DEFAULT_VALUE.EMPTY);
  const [showEditModal, setShowEditModal] = useState<boolean>(
    DEFAULT_VALUE.FALSE
  );
  const [deleteModal, setDeleteModal] = useState<boolean>(DEFAULT_VALUE.FALSE);
  const [editData, setEditData] = useState<{
    id: number;
    label: string;
  }>({ id: 0, label: DEFAULT_VALUE.EMPTY });

  const { data, isLoading, callApi } = useAPI(
    `${API.ROUTE.SEQUENCE}?page=${page}&pageSize=${TABLE.PAGE_SIZE}&search=${searchValue}`,
    API.METHOD.GET,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE
  );

  const addSequence = useAPI(
    API.ROUTE.SEQUENCE,
    API.METHOD.POST,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE,
    t(TRANSLATATION_KEY.SEQUENCE.FORM.API_SUCCESS_MSG)
  );

  const deleteSequence = useAPI(
    `${API.ROUTE.SEQUENCE}/${deleteId}`,
    API.METHOD.DELETE,
    DEFAULT_VALUE.NULL,
    DEFAULT_VALUE.TRUE,
    t(TRANSLATATION_KEY.SEQUENCE.FORM.API_DELETE_SUCCESS_MSG)
  );

  useEffect(() => {
    fetchCampaign();
  }, [page, searchValue]);

  useEffect(() => {
    if (data) {
      const { payload }: any = data;
      setSequences(payload?.sequences);
    }
  }, [data]);

  useEffect(() => {
    if (addSequence.data) {
      const { payload }: any = addSequence.data;
      navigate(ROUTES.STEP + "/" + payload.sequenceId);
    }
  }, [addSequence.data]);

  useEffect(() => {
    if (deleteSequence.data) {
      fetchCampaign();
    }
  }, [deleteSequence.data]);

  const toggleModalHandler = () => {
    addSequence.callApi();
  };

  const toggleEditModalHandler = () => {
    setShowEditModal(DEFAULT_VALUE.FALSE);
    callApi();
  };

  const fetchCampaign = () => {
    callApi();
  };

  const deleteCampaignHandler = () => {
    deleteSequence.callApi();
    setDeleteId(null);
    setDeleteModal(false);
  };

  const navigateToViewSeq = (id: string) => {
    navigate(ROUTES.STEP + "/Steps/" + id);
  };

  return (
    <>
      <DeleteConfirmation
        open={deleteModal}
        title="Campaign"
        onChange={() => setDeleteModal(!deleteModal)}
        onDelete={deleteCampaignHandler}
      />
      <PageHeading
        title="Campaign"
        subTitle="Create your campaign to start sending emails"
        showAddBtn={true}
        btnTitle="Create Campaign"
        onBtnClick={toggleModalHandler}
      />

      <div>
        <div className="p-4 md:p-8 flow-root">
          <ul role="list" className="-my-5">
            <li className="py-2 px-4 bg-[#f3f4f6] rounded-lg">
              <div className="flex items-center space-x-4">
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-bold text-gray-900">
                    Status
                  </p>
                </div>
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-bold text-gray-900">
                    Name
                  </p>
                </div>
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-bold text-gray-900">
                    Steps
                  </p>
                </div>
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-bold text-gray-900">
                    Opened
                  </p>
                </div>
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-bold text-gray-900">
                    Replied
                  </p>
                </div>
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-bold text-gray-900">
                    Bounced
                  </p>
                </div>
                <div className="min-w-0">
                  <p className="truncate text-sm font-bold text-gray-900">
                    Action
                  </p>
                </div>
              </div>
            </li>
            {!isLoading &&
              sequences.length > 0 &&
              sequences.map((seq: any) => (
                <li
                  key={seq._id}
                  className="py-2 px-4 border mt-2 border-[#e2e8f0] rounded-md hover:bg-[#f3f4f6]">
                  <div className="flex items-center space-x-4">
                    <div className="min-w-0 flex-1">
                      <TableStatus
                        id={seq._id}
                        activateStatusUrl={`${API.ROUTE.SEQUENCE}/activate-sequence/`}
                        deactivateStatusUrl={`${API.ROUTE.SEQUENCE}/deactivate-sequence/`}
                        value={seq.sequenceStatus}
                        refreshData={() => {
                          callApi();
                        }}
                      />
                    </div>
                    <div
                      className="min-w-0 flex-1 cursor-pointer"
                      onClick={() => navigateToViewSeq(seq._id)}>
                      <p className="truncate text-sm font-medium text-gray-900">
                        {seq.name}
                      </p>
                    </div>
                    <div
                      className="min-w-0 flex-1 cursor-pointer"
                      onClick={() => navigateToViewSeq(seq._id)}>
                      <span
                        className={`inline-flex items-center rounded px-2 py-0.5 text-xs font-medium `}>
                        {seq.stepLength}
                      </span>
                    </div>
                    <div
                      className="min-w-0 flex-1 cursor-pointer"
                      onClick={() => navigateToViewSeq(seq._id)}>
                      <span
                        className={`inline-flex items-center rounded px-2 py-0.5 text-xs font-medium `}>
                        {seq.slplData && seq.slplData.length > 0
                          ? seq.slplData[0].openedCount
                          : 0}
                      </span>
                    </div>
                    <div
                      className="min-w-0 flex-1 cursor-pointer"
                      onClick={() => navigateToViewSeq(seq._id)}>
                      <span
                        className={`inline-flex items-center rounded px-2 py-0.5 text-xs font-medium `}>
                        {seq.slplData && seq.slplData.length > 0
                          ? seq.slplData[0].repliedCount
                          : 0}
                      </span>
                    </div>
                    <div
                      className="min-w-0 flex-1 cursor-pointer"
                      onClick={() => navigateToViewSeq(seq._id)}>
                      <span
                        className={`inline-flex items-center rounded px-2 py-0.5 text-xs font-medium`}>
                        {seq.slplData && seq.slplData.length > 0
                          ? seq.slplData[0].bouncedCount
                          : 0}
                      </span>
                    </div>
                    <div>
                      <Menu
                        as="div"
                        className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95">
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setEditData({
                                        id: seq._id,
                                        label: seq.name
                                      });
                                      setShowEditModal(DEFAULT_VALUE.TRUE);
                                    }}
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm"
                                    )}>
                                    Edit
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      setDeleteId(seq._id);
                                      setDeleteModal(true);
                                    }}
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm"
                                    )}>
                                    Delete
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </li>
              ))}

            {!isLoading && sequences.length <= 0 && (
              <div className="w-full bg-[#f3f4f6] mt-2 rounded-lg py-32">
                <NoData buttonName="Sequence" onAdd={toggleModalHandler} />
              </div>
            )}

            {isLoading && (
              <div className="w-full">
                <TableSkeletonLoading />
              </div>
            )}
          </ul>
        </div>
      </div>

      <Drawer
        title={t(TRANSLATATION_KEY.SEQUENCE.FORM.EDIT_SEQUENCE)}
        showModal={showEditModal}
        width={DRAWER_WIDTH.MIN as DrawerWidthType}
        onCloseModal={toggleEditModalHandler}>
        <EditSequence
          editData={editData}
          closeModal={() => {
            setShowEditModal(DEFAULT_VALUE.FALSE);
            callApi();
          }}
        />
      </Drawer>
    </>
  );
};

export default Sequence;
