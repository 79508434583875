// Dependencies
import { ReactNode } from "react";

// Components
import AccountTypeCard from "./account-type-card";

// SVG Icon
import Google from "../../assets/svg/google-icon";
import Microsoft from "../../assets/svg/microsoft-icon";
import SmtpImap from "../../assets/svg/smtp-imap-icon";

// Constants
import { ADD_EMAIL_ACCOUNT_BY } from "../../utils/contants";

type AddEmailAccountOptionsProps = {
  closeModal: (value: boolean) => void;
};

const AddEmailAccountOptions = ({
  closeModal
}: AddEmailAccountOptionsProps) => {
  type AccountTypeProps = {
    name: string;
    subTitle: string;
    icon: ReactNode;
  };

  const accountTypes: AccountTypeProps[] = [
    {
      name: ADD_EMAIL_ACCOUNT_BY.GOOGLE.TITLE,
      subTitle: ADD_EMAIL_ACCOUNT_BY.GOOGLE.SUB_TITLE,
      icon: <Google />
    },
    {
      name: ADD_EMAIL_ACCOUNT_BY.MICROSOFT.TITLE,
      subTitle: ADD_EMAIL_ACCOUNT_BY.MICROSOFT.SUB_TITLE,
      icon: <Microsoft />
    },
    {
      name: ADD_EMAIL_ACCOUNT_BY.SMTP.TITLE,
      subTitle: ADD_EMAIL_ACCOUNT_BY.SMTP.SUB_TITLE,
      icon: <SmtpImap />
    }
  ];

  return (
    <div className="p-5">
      <p className="mb-5 text-sm text-gray-500 dark:text-white">
        Connect your email account from which you want to send email from
        PolarConnect
      </p>
      <p className="font-bold text-sm mb-5">
        select the email service you use:
      </p>
      <div className="mx-auto w-full max-w-[1170px]">
        <div className="grid grid-cols-1 gap-7.5 sm:grid-cols-2 xl:grid-cols-3">
          {accountTypes.length &&
            accountTypes.map((accountType, index) => (
              <AccountTypeCard
                key={index}
                accountType={accountType}
                closeModal={closeModal}
              />
            ))}
        </div>
      </div>
      <p className="mt-5 text-xs dark:text-white">
        Note: We dont store your mailbox data & you can remove your email
        account at any time.
      </p>
    </div>
  );
};

export default AddEmailAccountOptions;
